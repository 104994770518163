import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { InsuranceReadinessIssueManualVerificationRequiredType } from '@headway/api/models/InsuranceReadinessIssueManualVerificationRequired';
import { InsuranceReadinessIssueNoMentalHealthBenefitsType } from '@headway/api/models/InsuranceReadinessIssueNoMentalHealthBenefits';
import { InsuranceReadinessIssueOutOfNetworkType } from '@headway/api/models/InsuranceReadinessIssueOutOfNetwork';
import { InsuranceReadinessIssueTerminatedType } from '@headway/api/models/InsuranceReadinessIssueTerminated';
import { PatientMismatchInputField } from '@headway/api/models/PatientMismatchInputField';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';

export const MismatchedInsuranceFieldType = {
  [PatientMismatchInputField.FIRST_NAME]: 'first name',
  [PatientMismatchInputField.LAST_NAME]: 'last name',
  [PatientMismatchInputField.DOB]: 'date of birth',
  [PatientMismatchInputField.MEMBER_ID]: 'member ID',
};

export const getHumanInputErrorMismatchString = (
  patientMismatchInputFields?: Array<PatientMismatchInputField>
): String => {
  const fields = patientMismatchInputFields
    ?.map((field) => MismatchedInsuranceFieldType[field])
    .sort();

  let string = '';
  switch (fields?.length) {
    case 1:
      string = `Your ${fields[0]} doesn't match your insurance card.`;
      break;
    case 2:
      string = `Both your ${fields[0]} and ${fields[1]} don't match your insurance card.`;
      break;
    case 3:
      string = `Your ${fields[0]}, ${fields[1]}, and ${fields[2]} don't match your insurance card.`;
      break;
    default:
      string = 'Likely typo in insurance details.';
  }
  return string;
};
