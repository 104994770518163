import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { theme } from '@headway/ui/theme';

export const StyledSectionHeader = styled.h4`
  font-family: ${theme.fontFamily.postGrotesk};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.xl};
  color: ${theme.color.black};
  margin-top: ${theme.space.xl2};
  margin-bottom: ${theme.space.lg};
`;

export const bodyTextCss = css`
  color: ${theme.color.black};
  font-family: ${theme.fontFamily.postGrotesk};
  font-size: ${theme.fontSize.base};
  line-height: ${theme.fontSize.xl2};
`;

export const formSectionTitleCss = css`
  color: ${theme.color.black};
  font-family: ${theme.fontFamily.postGrotesk};
  font-size: ${theme.fontSize.xl};
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: ${theme.space.xs};
`;
