import { Divider } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import { ReactNode } from 'react';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { useFlag } from '@headway/feature-flags/react';
import { ContentText } from '@headway/helix/ContentText';
import { IconArmchair } from '@headway/helix/icons/Armchair';
import { IconCalendarCheck } from '@headway/helix/icons/CalendarCheck';
import { IconChair } from '@headway/helix/icons/Chair';
import { IconMoney } from '@headway/helix/icons/Money';
import { IconPhone } from '@headway/helix/icons/Phone';
import { IconVideoChat } from '@headway/helix/icons/VideoChat';
import { MEDICAID_PLAN_DETECTION } from '@headway/shared/FeatureFlags/flagNames';
import { WithStoresChildProps } from '@headway/shared/types/stores';
import { transformCloudflareImg } from '@headway/shared/utils/cloudflareImage';
import { ProviderProfilePhoto } from '@headway/ui/providers/ProviderProfilePhoto';

import { withStores } from '../../../stores/withStores';

interface AppointmentDetailsCardProps extends WithStoresChildProps {
  provider: ProviderRead;
  /**
   * This component must be given one of `availability` or `appointment`. If not given either, it will return null.
   * If given both `availability` and `appointment`, the `appointment` data will be used.
   * If the card should show details for an appointment that has not yet been created/booked, pass in `availability`
   */
  availability?: AvailabilityEventResponse;
  /**
   * If the card should show details for an appointment that has been created, pass in the `appointment` object
   */
  appointment?: ConcreteProviderEventRead;
  showPaymentDetails?: boolean;
  isSessionConfirmed?: boolean;
  variant?: 'default' | 'branded';
  shouldShowAnthemEAPExperience?: boolean;
}

export const AppointmentDetailsCard = withStores(
  ({
    AuthStore: { user },
    provider,
    availability,
    appointment,
    showPaymentDetails = false,
    isSessionConfirmed = false,
    variant = 'default',
    shouldShowAnthemEAPExperience = false,
  }: AppointmentDetailsCardProps) => {
    const isMedicaidPlanDetectionEnabled = useFlag(
      MEDICAID_PLAN_DETECTION,
      false
    );
    const startDate = appointment?.startDate ?? availability?.startDate;
    const endDate = appointment?.endDate ?? availability?.endDate;
    const formatedSessionStartDate =
      moment(startDate).format('MMM Do, h:mm') +
      '-' +
      moment(endDate).format('h:mm a');

    const isFreePhoneConsultation =
      (appointment?.type ?? availability?.type) ===
      ProviderEventType.INTAKE_CALL;

    const cancellationCutoffDate = moment(startDate)
      .subtract(provider.cancellationCutoffHours ?? 0, 'hours')
      .format('MMMM D, h:mma');

    const isTelehealthSession =
      appointment?.telehealth ?? availability?.telehealth;
    const address =
      appointment?.address?.address ??
      availability?.address?.address ??
      provider?.providerAddresses?.find(
        (providerAddress) =>
          providerAddress.id === availability?.providerAddressId
      )?.address ??
      'In-person session';

    if (!appointment && !availability) {
      return null;
    }

    const isMedicaidPatient =
      user.activeUserInsurance?.isMedicaid && isMedicaidPlanDetectionEnabled;

    const hasNoCancellationFee =
      isFreePhoneConsultation || shouldShowAnthemEAPExperience;

    return (
      <div
        className={clsx(
          'flex h-fit w-full flex-col items-center justify-center gap-3 self-stretch rounded-[6px] p-5',
          {
            'bg-system-white border-1 border-system-borderGray border border-solid':
              variant === 'default',
            'bg-[#FFFFF7]': variant === 'branded',
          }
        )}
      >
        <div className="flex flex-row">
          <div className="items-center justify-center">
            <ProviderProfilePhoto
              photoUrl={transformCloudflareImg({
                src: provider.photoUrl,
                width: 80,
              })}
              css={{ height: '80px', width: '80px' }}
            />
          </div>
          <div className="flex flex-col space-y-2 py-1 pl-4">
            <ContentText>
              <b>
                {isFreePhoneConsultation ? 'Phone consultation' : 'Session'}{' '}
                with {provider.displayFirstName}
              </b>
            </ContentText>
            <div className="flex flex-col gap-1">
              <div className="flex items-start gap-2">
                <div className="h-5 shrink-0">
                  <IconCalendarCheck />
                </div>
                <ContentText variant="body-small">
                  {formatedSessionStartDate}
                </ContentText>
              </div>
              <div className="flex items-start gap-2">
                {isFreePhoneConsultation ? (
                  <>
                    <div className="h-5 shrink-0">
                      <IconPhone />
                    </div>
                    <ContentText variant="body-small">
                      Phone consultation
                    </ContentText>
                  </>
                ) : isTelehealthSession ? (
                  <>
                    <div className="h-5 shrink-0">
                      <IconVideoChat />
                    </div>
                    <ContentText variant="body-small">
                      Video session
                    </ContentText>
                  </>
                ) : (
                  <>
                    <div className="h-5 shrink-0">
                      <IconChair />
                    </div>
                    <ContentText variant="body-small">{address}</ContentText>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {showPaymentDetails && (
          <>
            <Divider
              orientation="horizontal"
              variant="middle"
              className={clsx('self-stretch', {
                'border-system-borderGray': variant === 'default',
                'border-[#FCEFC5]': variant === 'branded',
              })}
            />
            <div className="desktop:gap-10 desktop:flex desktop:max-w-none grid w-full max-w-[600px] grid-cols-2 items-center justify-center gap-3">
              <PaymentDetailWithIcon icon={<IconMoney />}>
                {shouldShowAnthemEAPExperience
                  ? 'Free with your EAP benefits'
                  : isMedicaidPatient
                    ? 'Fully covered by your Medicaid plan'
                    : isFreePhoneConsultation
                      ? 'Free phone consultation'
                      : isSessionConfirmed
                        ? 'You won’t be charged until after your session'
                        : 'Book now, pay after session'}
              </PaymentDetailWithIcon>
              <PaymentDetailWithIcon icon={<IconArmchair />}>
                {isMedicaidPatient
                  ? 'No cancellation fee'
                  : hasNoCancellationFee
                    ? 'Free cancellation'
                    : `Free cancellation until ${cancellationCutoffDate}`}
              </PaymentDetailWithIcon>
            </div>
          </>
        )}
      </div>
    );
  }
);

type PaymentDetailWithIconProps = {
  icon: ReactNode;
  children: ReactNode;
};

const PaymentDetailWithIcon = ({
  icon,
  children,
}: PaymentDetailWithIconProps) => {
  return (
    <div className="flex shrink grow-0 flex-col items-center justify-center gap-3 px-3 text-center">
      {icon}
      <p>
        <ContentText variant="caption">{children}</ContentText>
      </p>
    </div>
  );
};
