import clsx from 'clsx';
import { NextSeo } from 'next-seo';
import { forwardRef, ReactNode } from 'react';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderSpecialtyBookingPreference } from '@headway/api/models/ProviderSpecialtyBookingPreference';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { HeadwayLogo } from '@headway/helix/HeadwayLogo';
import { IconCaretLeft } from '@headway/helix/icons/CaretLeft';
import { theme } from '@headway/helix/theme';
import seatedTherapySessionImg from '@headway/shared/assets/img/product/seated_therapy_session.webp';
import therapistSessionImg from '@headway/shared/assets/img/product/therapist_session.webp';

import { PageWrapper } from '../../Nav/PageWrapper';
import { AppointmentDetailsCard } from '../components/AppointmentDetailsCard';
import { HeadwayLetter } from '../components/HeadwayLetter';
import {
  HelixMobileProgressHeader,
  HelixProgressBar,
} from '../components/HelixProgressBar';
import {
  BookAppointmentStep,
  LEFT_COLUMN_CONTENT_VARIANT_BY_STEP,
  STEPS_WITH_DESKTOP_BACK_BUTTON,
  STEPS_WITH_DESKTOP_PROGRESS_BAR_V2,
  STEPS_WITH_MOBILE_BACK_BUTTON,
  STEPS_WITH_MOBILE_PROGRESS_BAR_V2,
} from '../constants';
import { getPreviousBookAppointmentStep } from '../utils';

export enum BookingPageLeftColumnContentVariants {
  UnconfirmedAppointmentCard = 'unconfirmedAppointmentCard',
  ConfirmedAppointmentCard = 'confirmedAppointmentCard',
  // TODO: Integrate Checkout redesign changes with Headway Letter changes
  HeadwayLetter = 'letter',
}

type BookingPageV2LayoutProps = {
  children: ReactNode;
  user?: UserRead;
  step: BookAppointmentStep;
  selectedAppointmentType: ProviderSpecialtyBookingPreference;
  provider: ProviderRead;
  availability: AvailabilityEventResponse | null;
  patientFirstName: string;
  onNavigationBack?: () => void;
  shouldShowAnthemEAPExperience?: boolean;
};

const BookingPageV2Layout = forwardRef<
  HTMLDivElement,
  BookingPageV2LayoutProps
>(
  (
    {
      children,
      user,
      step,
      selectedAppointmentType,
      provider,
      availability,
      patientFirstName,
      onNavigationBack,
      shouldShowAnthemEAPExperience = false,
    }: BookingPageV2LayoutProps,
    ref
  ) => {
    const desktopLeftColumnContentVariant =
      LEFT_COLUMN_CONTENT_VARIANT_BY_STEP[step];
    const shouldShowNavigationHeader =
      STEPS_WITH_DESKTOP_PROGRESS_BAR_V2.includes(step);
    const shouldShowDesktopBackButton =
      STEPS_WITH_DESKTOP_BACK_BUTTON.includes(step);
    const shouldShowMobileBackButton =
      STEPS_WITH_MOBILE_BACK_BUTTON.includes(step);
    const shouldShowMobileProgressBar =
      STEPS_WITH_MOBILE_PROGRESS_BAR_V2.includes(step);

    const desktopImageSrc =
      desktopLeftColumnContentVariant ===
      BookingPageLeftColumnContentVariants.HeadwayLetter
        ? therapistSessionImg.src
        : seatedTherapySessionImg.src;

    const hasPreviousBookAppointmentStep = !!getPreviousBookAppointmentStep({
      currentStep: step,
      availability,
      isInOutage: !!user?.activeUserInsurance?.isInOutage,
    });

    return (
      <PageWrapper hideFooter hideHeader>
        <NextSeo
          title="Book Appointment"
          description="Book an appointment with a Headway provider"
          openGraph={{}} // empty object currently needed due to https://github.com/garmeeh/next-seo/issues/544
        />
        <div className="relative flex h-[100svh] w-full flex-row" ref={ref}>
          {/* left side for desktop */}
          {
            <div className="desktop:flex hidden h-screen w-[50%]">
              <div className="relative h-screen w-full object-fill object-center">
                <img
                  src={desktopImageSrc}
                  className="h-screen w-full object-cover object-center"
                  alt="therapist session"
                />

                {/* overlay on top of image */}
                <div className="absolute inset-0 flex flex-col gap-[3.5rem] overflow-y-auto px-[6rem] py-[5rem]">
                  <div className="w-[10rem]">
                    <HeadwayLogo color="green" text="Headway" />
                  </div>

                  {desktopLeftColumnContentVariant &&
                    [
                      BookingPageLeftColumnContentVariants.ConfirmedAppointmentCard,
                      BookingPageLeftColumnContentVariants.UnconfirmedAppointmentCard,
                    ].includes(desktopLeftColumnContentVariant) &&
                    availability && (
                      <>
                        <div className="flex grow flex-col items-center justify-center">
                          <AppointmentDetailsCard
                            provider={provider}
                            availability={availability}
                            variant="branded"
                            showPaymentDetails
                            isSessionConfirmed={
                              desktopLeftColumnContentVariant ===
                              BookingPageLeftColumnContentVariants.ConfirmedAppointmentCard
                            }
                            shouldShowAnthemEAPExperience={
                              shouldShowAnthemEAPExperience
                            }
                          />
                        </div>
                        {/* This empty div is the same height as the Headway logo to ensure the appointment card is centered vertically */}
                        <div className="h-8" />
                      </>
                    )}
                  {desktopLeftColumnContentVariant ===
                    BookingPageLeftColumnContentVariants.HeadwayLetter && (
                    <div className="mb-auto mt-auto max-w-full items-center justify-center rounded-[24px] bg-[#FFFFF7] px-[4rem] py-[4rem]">
                      <HeadwayLetter
                        providerName={provider.displayFirstName}
                        patientFirstName={patientFirstName}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          }

          {/* right side for desktop, main screen on mobile */}
          <div
            className={clsx(
              'desktop:px-[5rem] desktop:py-[4rem] desktop:mb-0 desktop:mt-0 desktop:gap-12 mb-9 mt-2 flex flex-1 flex-col gap-8 overflow-y-auto px-4 pt-0'
            )}
          >
            {/* Mobile Header */}
            <div className="desktop:hidden flex flex-col">
              <HelixMobileProgressHeader
                onNavBack={
                  shouldShowMobileBackButton ? onNavigationBack : undefined
                }
                step={step}
                selectedAppointmentType={selectedAppointmentType}
                shouldShowAnthemEAPExperience={shouldShowAnthemEAPExperience}
                hideProgressBar={!shouldShowMobileProgressBar}
                stickyHeader={true}
              />
            </div>

            {(shouldShowNavigationHeader || shouldShowDesktopBackButton) && (
              <div className="desktop:flex hidden flex-col gap-4">
                {/* Back Button - Desktop */}
                {shouldShowDesktopBackButton && (
                  <Button variant="link" onPress={onNavigationBack}>
                    <span className="desktop:flex hidden items-center justify-start gap-2">
                      <IconCaretLeft
                        variant="outlined"
                        type="bold"
                        color={theme.color.system.textBlack}
                        size="1em"
                      />
                      <ContentText variant="body/medium">
                        {hasPreviousBookAppointmentStep
                          ? 'Back'
                          : `Back to ${provider.displayFirstName}’s profile`}
                      </ContentText>
                    </span>
                  </Button>
                )}
                {/* Checkout Steps - Desktop */}
                {shouldShowNavigationHeader && (
                  <HelixProgressBar
                    step={step}
                    selectedAppointmentType={selectedAppointmentType}
                    shouldShowAnthemEAPExperience={
                      shouldShowAnthemEAPExperience
                    }
                  />
                )}
              </div>
            )}
            {children}
          </div>
        </div>
      </PageWrapper>
    );
  }
);

export default BookingPageV2Layout;
