import clsx from 'clsx';
import React from 'react';

import { ProviderSpecialtyBookingPreference } from '@headway/api/models/ProviderSpecialtyBookingPreference';
import { ContentText } from '@headway/helix/ContentText';
import { HeadwayLogo } from '@headway/helix/HeadwayLogo';
import { IconButton } from '@headway/helix/IconButton';
import { IconCaretLeft } from '@headway/helix/icons/CaretLeft';
import { IconCheck } from '@headway/helix/icons/Check';
import { theme } from '@headway/helix/theme';

import { BookAppointmentStep } from '../constants';

interface ProgressBarProps {
  step: BookAppointmentStep;
  selectedAppointmentType: ProviderSpecialtyBookingPreference;
}

interface NumberCircleProps {
  number: number;
  active: boolean;
  completed: boolean;
}

const NumberedCircle = ({ number, active, completed }: NumberCircleProps) => {
  return (
    <div
      className={clsx(
        'flex h-5 w-5 items-center justify-center rounded-full text-center text-sm',
        {
          'bg-background-selected-hover text-system-green border-0 border-solid font-bold':
            active || completed,
          'border-system-black text-system-black border border-solid':
            !active && !completed,
        }
      )}
    >
      {completed ? (
        <IconCheck className="h-3 w-3" />
      ) : (
        <ContentText
          variant="caption"
          color={active ? 'foreground/compliance-secondary' : undefined}
        >
          {number}
        </ContentText>
      )}
    </div>
  );
};

interface StepProps {
  number: number;
  active: boolean;
  completed: boolean;
  text: string;
}

const Step = ({ number, active, completed, text }: StepProps) => {
  return (
    <div
      className={clsx(
        'flex w-12 shrink-0 grow-0 flex-col items-center justify-center gap-3 text-sm leading-4',
        'desktop:whitespace-nowrap desktop:flex-row desktop:gap-1 desktop:w-fit'
      )}
    >
      <NumberedCircle number={number} active={active} completed={completed} />
      <div className="desktop:inline hidden">
        <ContentText variant="body">
          {active ? <b>{text}</b> : text}
        </ContentText>
      </div>
      <div className="desktop:hidden">
        <ContentText variant="caption">
          {active ? <b>{text}</b> : text}
        </ContentText>
      </div>
    </div>
  );
};

interface ProgressBarPropsContainer {
  children: React.ReactNode;
}

const ProgressBarContainer = ({ children }: ProgressBarPropsContainer) => {
  return (
    <div className={clsx('desktop:px-0 flex w-full justify-between px-1')}>
      {children}
    </div>
  );
};

const Divider = () => {
  return (
    <span className="border-system-borderGray desktop:mt-0 desktop:self-center mx-2 mt-2 flex shrink flex-grow border-b-0 border-l-0 border-r-0 border-t border-solid" />
  );
};

interface ProgressBarProps {
  step: BookAppointmentStep;
  selectedAppointmentType: ProviderSpecialtyBookingPreference;
  shouldShowAnthemEAPExperience?: boolean;
}

export const HelixProgressBar = ({
  step,
  selectedAppointmentType,
  shouldShowAnthemEAPExperience = false,
}: ProgressBarProps) => {
  if (shouldShowAnthemEAPExperience) {
    return (
      <ProgressBarContainer>
        <Step
          number={1}
          active={[
            BookAppointmentStep.ACCOUNT,
            BookAppointmentStep.EMAIL_VERIFICATION,
          ].includes(step)}
          completed={[
            BookAppointmentStep.EAP,
            BookAppointmentStep.INSURANCE,
            BookAppointmentStep.TELEHEALTH,
            BookAppointmentStep.EAP_CHECKOUT,
            BookAppointmentStep.CONFLICT,
            BookAppointmentStep.SUCCESS,
            BookAppointmentStep.ACKNOWLEDGEMENT,
          ].includes(step)}
          text={'Account'}
        />
        <Divider />
        <Step
          number={2}
          active={[BookAppointmentStep.EAP].includes(step)}
          completed={[
            BookAppointmentStep.EAP_CHECKOUT,
            BookAppointmentStep.CONFLICT,
            BookAppointmentStep.SUCCESS,
            BookAppointmentStep.ACKNOWLEDGEMENT,
          ].includes(step)}
          text={'EAP'}
        />
        <Divider />
        <Step
          number={3}
          active={[BookAppointmentStep.EAP_CHECKOUT].includes(step)}
          completed={step === BookAppointmentStep.SUCCESS}
          text={'Confirm'}
        />
      </ProgressBarContainer>
    );
  }

  if (
    selectedAppointmentType === ProviderSpecialtyBookingPreference.INTAKE_CALL
  ) {
    return (
      <ProgressBarContainer>
        <Step
          number={1}
          active={[
            BookAppointmentStep.ACCOUNT,
            BookAppointmentStep.EMAIL_VERIFICATION,
          ].includes(step)}
          completed={[
            BookAppointmentStep.INSURANCE,
            BookAppointmentStep.TELEHEALTH,
            BookAppointmentStep.CHECKOUT,
            BookAppointmentStep.CONFLICT,
            BookAppointmentStep.SUCCESS,
            BookAppointmentStep.ACKNOWLEDGEMENT,
          ].includes(step)}
          text={'Account'}
        />
        <Divider />
        <Step
          number={2}
          active={[
            BookAppointmentStep.INSURANCE,
            BookAppointmentStep.TELEHEALTH,
          ].includes(step)}
          completed={[
            BookAppointmentStep.CHECKOUT,
            BookAppointmentStep.CONFLICT,
            BookAppointmentStep.SUCCESS,
            BookAppointmentStep.ACKNOWLEDGEMENT,
          ].includes(step)}
          text={'Insurance'}
        />
        <Divider />
        <Step
          number={3}
          active={[BookAppointmentStep.CHECKOUT].includes(step)}
          completed={[BookAppointmentStep.SUCCESS].includes(step)}
          text={'Confirm'}
        />
      </ProgressBarContainer>
    );
  }

  return (
    <ProgressBarContainer>
      <Step
        number={1}
        active={[
          BookAppointmentStep.ACCOUNT,
          BookAppointmentStep.EMAIL_VERIFICATION,
        ].includes(step)}
        completed={[
          BookAppointmentStep.INSURANCE,
          BookAppointmentStep.TELEHEALTH,
          BookAppointmentStep.CHECKOUT,
          BookAppointmentStep.CONFLICT,
          BookAppointmentStep.SUCCESS,
          BookAppointmentStep.ACKNOWLEDGEMENT,
        ].includes(step)}
        text={'Account'}
      />
      <Divider />
      <Step
        number={2}
        active={[
          BookAppointmentStep.INSURANCE,
          BookAppointmentStep.TELEHEALTH,
        ].includes(step)}
        completed={[
          BookAppointmentStep.CHECKOUT,
          BookAppointmentStep.CONFLICT,
          BookAppointmentStep.SUCCESS,
          BookAppointmentStep.ACKNOWLEDGEMENT,
        ].includes(step)}
        text={'Insurance'}
      />
      <Divider />
      <Step
        number={3}
        active={[BookAppointmentStep.CHECKOUT].includes(step)}
        completed={step === BookAppointmentStep.SUCCESS}
        text={'Payment'}
      />
    </ProgressBarContainer>
  );
};

interface MobileProgressHeaderProps {
  step: BookAppointmentStep;
  selectedAppointmentType: ProviderSpecialtyBookingPreference;
  onNavBack?: () => void;
  shouldShowAnthemEAPExperience?: boolean;
  hideProgressBar?: boolean;
  stickyHeader?: boolean;
  variant?: 'default' | 'white';
}

export const HelixMobileProgressHeader = ({
  step,
  selectedAppointmentType,
  onNavBack,
  shouldShowAnthemEAPExperience = false,
  hideProgressBar,
  stickyHeader,
  variant = 'default',
}: MobileProgressHeaderProps) => {
  return (
    <div
      className={clsx(
        'z-base flex flex-col justify-center',
        stickyHeader ? 'pt-[76px]' : 'relative pt-4'
      )}
    >
      <div
        className={clsx(
          'flex flex-row items-center justify-between p-2',
          stickyHeader && 'absolute left-0 right-0 top-0',
          {
            'bg-system-white': variant === 'default',
          }
        )}
      >
        <div className="h-[60px] shrink grow basis-0">
          {onNavBack && (
            <div className="flex self-center">
              <div className="[&>button]:pl-1.4 mr-3 p-2">
                <IconButton
                  onPress={onNavBack}
                  aria-label="Back"
                  variant="transparent"
                >
                  <IconCaretLeft
                    className="h-5 w-5"
                    color={
                      variant === 'white'
                        ? theme.color.system.white
                        : theme.color.system.textBlack
                    }
                  />
                </IconButton>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-1 shrink grow basis-0 items-center justify-center">
          <div className="w-[120px]">
            <HeadwayLogo
              color={variant === 'white' ? 'beige' : 'green'}
              text="Headway"
            />
          </div>
        </div>
        <div className="h-[60px] shrink grow basis-0"></div>
      </div>
      {!hideProgressBar && (
        <HelixProgressBar
          step={step}
          selectedAppointmentType={selectedAppointmentType}
          shouldShowAnthemEAPExperience={shouldShowAnthemEAPExperience}
        />
      )}
    </div>
  );
};
