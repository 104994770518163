import { PaginatedPatientAssessmentRead } from '@headway/api/models/PaginatedPatientAssessmentRead';
import { PatientAssessmentApi } from '@headway/api/resources/PatientAssessmentApi';
import { useQuery, UseQueryOptions } from '@headway/shared/react-query';

export type UsePatientAssessmentsQueryKeyArgs = NonNullable<
  Parameters<typeof PatientAssessmentApi.getPaginatedPatientAssessments>[0]
>;

export const getUsePatientAssessmentsQueryKey = (
  filters: UsePatientAssessmentsQueryKeyArgs
) => ['patient-assessments', filters] as const;

export function usePatientAssessments<T = PaginatedPatientAssessmentRead>(
  filters: UsePatientAssessmentsQueryKeyArgs,
  options?: UseQueryOptions<
    PaginatedPatientAssessmentRead,
    unknown,
    T,
    ReturnType<typeof getUsePatientAssessmentsQueryKey>
  >
) {
  return useQuery(
    getUsePatientAssessmentsQueryKey(filters),
    () => PatientAssessmentApi.getPaginatedPatientAssessments(filters),
    options
  );
}
