import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { MarketRead } from '@headway/api/models/MarketRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserPriceEstimateResponse } from '@headway/api/models/UserPriceEstimateResponse';
import { UserRead } from '@headway/api/models/UserRead';
import { trackEvent } from '@headway/shared/utils/analytics';

import { PRICING_SUPPORT_TICKET_FILED_SESSION_KEY } from './constants';
import PricingCardStatus from './PricingCardStatus';
import { getPricingCardStatusFromPricingDetails } from './utils';

const getTrackingTableName = (
  cardState: PricingCardStatus,
  submittedEmail?: string
) => {
  switch (cardState) {
    case PricingCardStatus.COPAY_DUE:
      return 'COPAY';
    case PricingCardStatus.ZERO_DOLLAR_PRICE:
      return 'ZERO';
    case PricingCardStatus.DEDUCTIBLE_REMAINING:
      return 'DEDUCTIBLE';
    case PricingCardStatus.DEDUCTIBLE_REMAINING_PRESCRIBER:
      return 'DEDUCTIBLE_PRESCRIBER';
    case PricingCardStatus.EMPTY_INFORMATION:
      return 'EMPTY';
    case PricingCardStatus.PARTIAL_INFORMATION:
      return 'CARRIER_ONLY';
    case PricingCardStatus.ERROR_OUT_OF_NETWORK:
      return 'OON';
    case PricingCardStatus.ERROR_OUT_OF_NETWORK_REDIRECT:
      return 'OON_REDIRECT';
    case PricingCardStatus.ERROR_OUT_OF_STATE:
      return 'OUT_OF_STATE';
    case PricingCardStatus.NO_STATE:
      return 'NO_STATE';
    case PricingCardStatus.ERROR_OTHER_STATE_REDIRECT:
      return 'OTHER_STATE_REDIRECT';
    default:
      return submittedEmail === undefined
        ? undefined
        : submittedEmail
        ? 'CONTACTED_HEADWAY'
        : 'SUBMIT_EMAIL_REQUEST';
  }
};

export const trackPricingCardStatus = (
  pricingDetails: UserPriceEstimateResponse,
  provider: ProviderRead,
  user: UserRead,
  liveMarkets: MarketRead[],
  userCarrier?: FrontEndCarrierRead | undefined
) => {
  const cardState = getPricingCardStatusFromPricingDetails(
    pricingDetails,
    provider.isPrescriber,
    liveMarkets,
    user.lastSearchedState,
    userCarrier,
    user.activeUserInsurance
  );

  trackEvent({
    name: 'Price Estimate Viewed',
    properties: {
      providerId: provider.id,
    },
  });

  const val = sessionStorage.getItem(
    pricingSupportTicketFiledSessionKey(provider.id, user.email)
  );

  if (val === null) return;

  const trackingTable = getTrackingTableName(cardState, val);
  trackEvent({
    name: 'Pricing Card',
    properties: {
      providerId: provider.id,
      tableName: trackingTable,
    },
  });
};

export const pricingSupportTicketFiledSessionKey = (
  providerId: number,
  userEmail?: string
) =>
  [PRICING_SUPPORT_TICKET_FILED_SESSION_KEY, userEmail, providerId]
    .filter(Boolean)
    .join('_');
