import { datadogRum } from '@datadog/browser-rum';
import { css } from '@emotion/react';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect } from 'react';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { BillingType } from '@headway/api/models/BillingType';
import {
  InsuranceReadinessIssueInsufficientOrIncorrectInfo,
  InsuranceReadinessIssueInsufficientOrIncorrectInfoType,
} from '@headway/api/models/InsuranceReadinessIssueInsufficientOrIncorrectInfo';
import { LookupStatus } from '@headway/api/models/LookupStatus';
import { MarketRead } from '@headway/api/models/MarketRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserInsuranceDetailsState } from '@headway/api/models/UserInsuranceDetailsState';
import { UserRead } from '@headway/api/models/UserRead';
import { AuthApi } from '@headway/api/resources/AuthApi';
import { Button as HelixButton } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { Link } from '@headway/helix/Link';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme as helixTheme } from '@headway/helix/theme';
import { SealCheck } from '@headway/icons';
import { PRICE_ESTIMATE_DESCRIPTION } from '@headway/shared/constants/priceEstimateDescriptions';
import { NO_DATA_FLAT_FEE } from '@headway/shared/constants/userInsuranceOutage';
import { useAppointmentReadiness } from '@headway/shared/hooks/useAppointmentReadiness';
import { useMutation, UseQueryResult } from '@headway/shared/react-query';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { getPriceString } from '@headway/shared/utils/bookingUtils';
import { getAvoInsuranceFormContext } from '@headway/shared/utils/InsuranceLookupUtils';
import {
  getHasTrackableReadinessIssue,
  hasManualVerificationRequiredReadinessIssue,
  hasNoMentalHealthBenefitsReadinessIssue,
  hasNoRemainingSessionReadinessIssue,
  hasNoTelehealthBenefitsReadinessIssue,
  hasOutOfNetworkReadinessIssue,
  hasTerminatedReadinessIssue,
  readinessIssuesToAvoIneligibleLookupType,
} from '@headway/shared/utils/insuranceUtils';
import { formatPrice } from '@headway/shared/utils/payments';
import { identifyUser } from '@headway/shared/utils/rudderStack';
import { logException, logWarning } from '@headway/shared/utils/sentry';
import { isUserRegistered } from '@headway/shared/utils/userVerificationStatus';
import {
  getVerificationStatus,
  UserVerificationStatus,
} from '@headway/shared/utils/userVerificationStatus';
import { Button } from '@headway/ui/Button';
import { theme } from '@headway/ui/theme';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { withStores, WithStoresChildProps } from '../../../stores/withStores';
import { getVerifyInsuranceBenefitsProviderRedirectUrl } from '../../../utils/contactFormLinks';
import { getHumanInputErrorMismatchString } from '../../../utils/insuranceUtils';
import { isSelfPayUser } from '../../Account/modules/Insurance';
import { InsuranceIneligibilityExplainerModal } from '../../InsuranceIneligibilityExplainerModal';
import { Link as RouteLink } from '../../Link';
import PricingCardStatus from '../PricingCard/PricingCardStatus';

const OpenInsuranceModalButton = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => (
  <Button
    css={{ marginTop: theme.space.sm }}
    onClick={onClick}
    type="button"
    variant="outlined"
  >
    {children}
  </Button>
);

const ProvidersSearchButton = ({
  carrierName,
  stateMarket,
}: {
  carrierName?: string;
  stateMarket: MarketRead;
}) => (
  <div css={textButtonCss}>
    <RouteLink href={`/search/${stateMarket.slug}`}>
      <span>
        See {carrierName || 'in-network'} providers in {stateMarket.displayName}
      </span>
    </RouteLink>
  </div>
);

const FirstSessionInfoTooltip = () => (
  <Tooltip
    title={
      <span css={{ fontSize: theme.fontSize.xs, textAlign: 'center' }}>
        First sessions are typically more expensive because they are longer
      </span>
    }
    placement="top"
  >
    <InfoIcon
      fontSize="inherit"
      css={{ marginLeft: theme.space.xs2, marginTop: theme.space.xs2 }}
    />
  </Tooltip>
);

const CopayDueState = ({
  condensedPriceOnly,
  firstSessionMinPrice,
  isPhoneConsult,
  noLinks = false,
}: {
  condensedPriceOnly?: boolean;
  firstSessionMinPrice: number;
  isPhoneConsult?: boolean;
  noLinks?: boolean;
}) => {
  if (condensedPriceOnly) {
    return <div>{firstSessionMinPrice}/session</div>;
  }

  return (
    <>
      {isPhoneConsult && <IntakeCallPrice />}
      <div className="flex flex-wrap items-baseline">
        <div css={priceCss(isPhoneConsult ? false : undefined)}>
          ${firstSessionMinPrice}
        </div>
        <div className="pl-1">
          {' estimated cost '}
          {isPhoneConsult ? 'for full sessions' : 'per session'}
        </div>
      </div>
      <div className="pt-4">
        <p>
          <ContentText variant="body-small" color="foreground/secondary">
            {PRICE_ESTIMATE_DESCRIPTION}
          </ContentText>
        </p>
        {!noLinks && (
          <RouteLink href="/benefits">
            <p>
              <ContentText variant="body-small" color="foreground/compliance">
                Learn more about how your plan works.
              </ContentText>
            </p>
          </RouteLink>
        )}
      </div>
    </>
  );
};

const DeductibleRemainingState = ({
  hasSeenProviderBefore,
  condensedPriceOnly,
  firstSessionMinPrice,
  otherSessionsMinPrice,
  isPhoneConsult,
  noLinks = false,
}: {
  hasSeenProviderBefore: boolean;
  condensedPriceOnly?: boolean;
  firstSessionMinPrice: number;
  otherSessionsMinPrice: number;
  isPhoneConsult?: boolean;
  noLinks?: boolean;
}) => {
  if (condensedPriceOnly) {
    return <div>{formatPrice(otherSessionsMinPrice)} for most sessions</div>;
  }
  return (
    <>
      {isPhoneConsult && <IntakeCallPrice />}
      <div className="flex flex-col gap-3">
        {!hasSeenProviderBefore && (
          <div className="flex flex-wrap items-baseline">
            <div
              css={priceCss(
                isPhoneConsult ? false : undefined,
                theme.fontSize.lg
              )}
            >
              {formatPrice(firstSessionMinPrice)}
            </div>
            <div className="pl-1">
              {' estimated cost for the 1st session'}
              {!isPhoneConsult &&
                firstSessionMinPrice > otherSessionsMinPrice && (
                  <FirstSessionInfoTooltip />
                )}
            </div>
          </div>
        )}
        <div className="flex flex-wrap items-baseline">
          <div
            css={priceCss(
              isPhoneConsult ? false : undefined,
              theme.fontSize.lg
            )}
          >
            {formatPrice(otherSessionsMinPrice)}
          </div>{' '}
          <div className="pl-1">
            {` estimated cost${!hasSeenProviderBefore ? ' for most others' : ''}`}
          </div>
        </div>
      </div>
      <div className="pt-4">
        <p>
          <ContentText variant="body-small" color="foreground/secondary">
            {PRICE_ESTIMATE_DESCRIPTION}
          </ContentText>
        </p>
        {!noLinks && (
          <RouteLink href="/benefits">
            <p>
              <ContentText variant="body-small" color="foreground/compliance">
                Learn more about how your plan works.
              </ContentText>
            </p>
          </RouteLink>
        )}
      </div>
    </>
  );
};

const DeductibleRemainingRangeState = ({
  hasSeenProviderBefore,
  condensedPriceOnly,
  firstSessionMinPrice,
  otherSessionsMaxPrice,
  otherSessionsMinPrice,
  isPhoneConsult,
  noLinks = false,
}: {
  hasSeenProviderBefore: boolean;
  condensedPriceOnly?: boolean;
  firstSessionMinPrice: number;
  otherSessionsMaxPrice: number;
  otherSessionsMinPrice: number;
  isPhoneConsult?: boolean;
  noLinks?: boolean;
}) => {
  if (condensedPriceOnly) {
    return (
      <div>
        {getPriceString(otherSessionsMinPrice, otherSessionsMaxPrice)} for most{' '}
        sessions
      </div>
    );
  }
  return (
    <>
      {isPhoneConsult && <IntakeCallPrice />}
      <div className="flex flex-col gap-3">
        {!hasSeenProviderBefore && (
          <div className="flex flex-wrap items-baseline">
            <div
              css={priceCss(
                isPhoneConsult ? false : undefined,
                theme.fontSize.lg
              )}
            >
              {formatPrice(firstSessionMinPrice)}
            </div>
            <div className="pl-1">
              {' estimated cost for the 1st session'}
              {!isPhoneConsult &&
                firstSessionMinPrice > otherSessionsMinPrice && (
                  <FirstSessionInfoTooltip />
                )}
            </div>
          </div>
        )}

        <div className="flex flex-wrap items-baseline">
          <div
            css={priceCss(
              isPhoneConsult ? false : undefined,
              theme.fontSize.lg
            )}
          >
            {getPriceString(otherSessionsMinPrice, otherSessionsMaxPrice)}
          </div>{' '}
          <div className="pl-1">
            {` estimated cost${!hasSeenProviderBefore ? ' for most others' : ''}`}
          </div>
        </div>
      </div>
      <div className="pt-4">
        <p>
          <ContentText variant="body-small" color="foreground/secondary">
            {PRICE_ESTIMATE_DESCRIPTION}
          </ContentText>
        </p>
        {!noLinks && (
          <RouteLink href="/benefits">
            <p>
              <ContentText variant="body-small" color="foreground/compliance">
                Learn more about how your plan works.
              </ContentText>
            </p>
          </RouteLink>
        )}
      </div>
    </>
  );
};

const DeductibleRemainingPrescriberState = ({
  hasSeenProviderBefore,
  condensedPriceOnly,
  firstSessionMaxPrice,
  firstSessionMinPrice,
  otherSessionsMaxPrice,
  otherSessionsMinPrice,
  providerDisplayNames,
  isPhoneConsult,
  noLinks = false,
}: {
  hasSeenProviderBefore: boolean;
  condensedPriceOnly?: boolean;
  firstSessionMaxPrice: number;
  firstSessionMinPrice: number;
  otherSessionsMaxPrice: number;
  otherSessionsMinPrice: number;
  providerDisplayNames: string[];
  isPhoneConsult?: boolean;
  noLinks?: boolean;
}) => {
  if (condensedPriceOnly) {
    return (
      <div>
        {getPriceString(otherSessionsMinPrice, otherSessionsMaxPrice)} for most{' '}
        sessions
      </div>
    );
  }
  const isSingleProvider = providerDisplayNames.length === 1;
  const providerDisplayName = providerDisplayNames?.[0];
  return (
    <>
      {isPhoneConsult && <IntakeCallPrice />}
      <div className="flex flex-col gap-3">
        {!hasSeenProviderBefore && (
          <div className="flex flex-wrap items-baseline">
            <div
              css={priceCss(
                isPhoneConsult ? false : undefined,
                theme.fontSize.lg
              )}
            >
              {getPriceString(firstSessionMinPrice, firstSessionMaxPrice)}
            </div>
            <div className="pl-1">
              {' estimated cost for the 1st session'}
              {!isPhoneConsult &&
                firstSessionMinPrice > otherSessionsMinPrice && (
                  <FirstSessionInfoTooltip />
                )}
            </div>
          </div>
        )}
        <div className="flex flex-wrap items-baseline">
          <div
            css={priceCss(
              isPhoneConsult ? false : undefined,
              theme.fontSize.lg
            )}
          >
            {getPriceString(otherSessionsMinPrice, otherSessionsMaxPrice)}
          </div>
          <div className="pl-1">{` estimated cost${!hasSeenProviderBefore ? ' for most others' : ''}`}</div>
        </div>
      </div>
      <div className="pt-4">
        <p className="pb-3">
          <ContentText variant="body-small" color="foreground/secondary">
            We won’t know your price until your session is complete because{' '}
            {isSingleProvider
              ? `${providerDisplayName ?? 'this provider'} prescribes `
              : `these providers prescribe `}
            medication. {PRICE_ESTIMATE_DESCRIPTION}
          </ContentText>
        </p>
        {!noLinks && (
          <RouteLink href="/benefits">
            <p>
              <ContentText variant="body-small" color="foreground/compliance">
                Learn more about how your plan works.
              </ContentText>
            </p>
          </RouteLink>
        )}
      </div>
    </>
  );
};

const ZeroDollarPriceState = ({
  condensedPriceOnly,
  isPhoneConsult,
}: {
  condensedPriceOnly?: boolean;
  isPhoneConsult?: boolean;
}) => {
  if (condensedPriceOnly) {
    return <div>$0/session</div>;
  }
  return (
    <>
      {isPhoneConsult && <IntakeCallPrice />}
      <div className="flex flex-wrap items-baseline">
        <div css={priceCss(isPhoneConsult ? false : undefined)}>$0</div>
        <div className="pl-1">
          {isPhoneConsult ? 'for full sessions' : 'per session'}
        </div>
      </div>
      <div css={priceExplanationCss}>
        Because of your plan, you won’t owe anything for this session.{' '}
        {PRICE_ESTIMATE_DESCRIPTION}
      </div>
    </>
  );
};

const EAPPriceState = ({
  condensedPriceOnly,
  isPhoneConsult,
  applicableSessionCount,
  providerDisplayName,
}: {
  condensedPriceOnly?: boolean;
  isPhoneConsult?: boolean;
  applicableSessionCount?: number;
  providerDisplayName: string;
}) => {
  if (condensedPriceOnly) {
    return <div>$0/session</div>;
  }
  return (
    <>
      {isPhoneConsult && <IntakeCallPrice />}
      <div className="flex flex-wrap items-baseline">
        <div css={priceCss(false)}>$0</div>
        <div className="pl-1">
          for the first {applicableSessionCount} sessions
        </div>
      </div>
      <div css={priceExplanationCss}>
        Since {providerDisplayName} is covered by your EAP, you can attend{' '}
        {applicableSessionCount} sessions at no charge.
      </div>
      <div css={[priceExplanationCss, { marginTop: theme.space.xs4 }]}>
        After that, you may be able to continue seeing {providerDisplayName} at
        a low session cost through Headway, based on the benefits from your
        insurance plan.
      </div>
    </>
  );
};

const PausedLookupState = (
  <>
    <p>
      Our insurance processing partner is down at the moment, so we’re not able
      to get your plan details right now. Please try again shortly. (And don’t
      worry — this has nothing to do with your personal coverage!)
    </p>
  </>
);

const InsuranceOutageState = ({
  condensedPriceOnly,
  carrierName,
  firstSessionMinPrice,
  firstSessionMaxPrice,
  isInNoDataOutage,
  isInOldDataOutage,
  onClickUpdateInsuranceButton,
}: {
  condensedPriceOnly?: boolean;
  carrierName?: string;
  firstSessionMinPrice?: number;
  firstSessionMaxPrice?: number;
  isInNoDataOutage?: boolean;
  isInOldDataOutage?: boolean;
  onClickUpdateInsuranceButton: () => void;
}) => {
  if (condensedPriceOnly) {
    return (
      <div>
        ${firstSessionMinPrice}-{firstSessionMaxPrice}/session
      </div>
    );
  }
  return (
    <>
      <div className="flex flex-wrap items-baseline">
        <div css={priceCss(true, theme.fontSize.xl)}>
          {getPriceString(firstSessionMinPrice, firstSessionMaxPrice)}
        </div>
        <div className="pl-1">per session</div>
      </div>
      <div css={priceExplanationCss}>
        {isInNoDataOutage && (
          <>
            The system we use to verify benefits is currently down. For any
            upcoming sessions, you’ll pay a temporary flat fee of $
            {NO_DATA_FLAT_FEE}. As soon as we get final confirmation from{' '}
            {carrierName || 'your insurer'} we’ll charge or refund you if
            there’s a difference.
          </>
        )}
        {isInOldDataOutage && (
          <>
            The system we use to verify benefits is currently down. For any
            upcoming sessions, you’ll pay last session cost with a refund or
            second bill later if {carrierName || 'your insurer'} confirms a
            different price.
          </>
        )}
      </div>
      <OpenInsuranceModalButton onClick={onClickUpdateInsuranceButton}>
        Update insurance
      </OpenInsuranceModalButton>
    </>
  );
};

const PartialInformationState = ({
  condensedPriceOnly,
  isPhoneConsult,
  noLinks = false,
  onClickUpdateInsuranceButton,
}: {
  condensedPriceOnly?: boolean;
  isPhoneConsult?: boolean;
  noLinks?: boolean;
  onClickUpdateInsuranceButton: () => void;
}) => {
  if (condensedPriceOnly) {
    return <>See estimated cost</>;
  }
  return (
    <>
      {isPhoneConsult && <IntakeCallPrice />}
      {noLinks ? (
        <div css={priceExplanationCss}>
          We’ll verify your insurance and give you an instant estimate after you
          add your insurance.
        </div>
      ) : (
        <>
          <div>
            <GuidanceCard
              variant="positive"
              layout="vertical"
              icon={<SealCheck />}
            >
              <ContentText>
                <strong>Get an instant price estimate</strong>
              </ContentText>
              <ContentText>
                Add your insurance now to get an estimate of your session cost.
              </ContentText>
            </GuidanceCard>
            <p style={{ paddingTop: 20 }}>
              <HelixButton
                onPress={() => {
                  datadogRum.addAction(
                    `Booking Flow - Add Insurance button clicked from provider profile`
                  );
                  onClickUpdateInsuranceButton();
                }}
                variant="secondary"
              >
                Add insurance
              </HelixButton>
            </p>
          </div>
        </>
      )}
    </>
  );
};

const ErrorInNetworkState = withStores(
  ({
    AuthStore,
    condensedPriceOnly,
  }: {
    condensedPriceOnly?: boolean;
  } & WithStoresChildProps) => {
    const verifyEmailMutation = useMutation(
      () =>
        AuthApi.sendVerificationEmail(AuthStore.user.id, {
          redirectTo: window.location.pathname,
        }),
      {
        onSuccess: () => void notifySuccess('Verification email sent'),
        onError: (err) => {
          notifyError('Failed to send verification email');
          logException(err);
        },
      }
    );

    if (condensedPriceOnly) {
      return <div>Insurance issue</div>;
    }
    if (!process.env.GOOGLE_MAPS_API_KEY) {
      throw new Error('GOOGLE_MAPS_API_KEY expected');
    }
    const accountVerificationStatus = getVerificationStatus(AuthStore.user);

    const isSelfPay = isSelfPayUser(AuthStore.user);

    return (
      <>
        <div css={priceExplanationCss}>
          {accountVerificationStatus ===
          UserVerificationStatus.REGISTERED_AND_VERIFIED ? (
            <>
              <p>
                {isSelfPay
                  ? 'Your Headway sessions are currently out-of-pocket, so your insurance isn’t needed. If you intend to use your insurance, we’ll need to reach out to your insurance to manually verify your benefits as a next step.'
                  : 'We can’t calculate your price per session until your insurance is verified.'}
              </p>
              <Button
                css={priceCtaCss}
                color="primary"
                onClick={() =>
                  window.open(
                    getVerifyInsuranceBenefitsProviderRedirectUrl(),
                    '_blank'
                  )
                }
              >
                Verify insurance
              </Button>
            </>
          ) : accountVerificationStatus ===
            UserVerificationStatus.REGISTERED_NOT_VERIFIED ? (
            <>
              <p>
                Verify the email associated with your account to get your
                estimated price per session.
              </p>
              {!verifyEmailMutation.isSuccess && (
                <Button
                  disabled={verifyEmailMutation.isLoading}
                  css={priceCtaCss}
                  color="primary"
                  onClick={() => verifyEmailMutation.mutate()}
                >
                  Resend verification email
                </Button>
              )}
            </>
          ) : (
            <p>
              Log in to your Headway account to get your estimated price per
              session.
            </p>
          )}
        </div>
      </>
    );
  }
);

const ErrorOutOfNetwork = ({
  carrierName,
  condensedPriceOnly,
  providerDisplayNames,
  noLinks = false,
  onClickUpdateInsuranceButton,
}: {
  carrierName?: string;
  condensedPriceOnly?: boolean;
  providerDisplayNames: string[];
  noLinks?: boolean;
  onClickUpdateInsuranceButton: () => void;
}) => {
  const isSingleProvider = providerDisplayNames.length === 1;
  const providerDisplayName = providerDisplayNames?.[0];

  return condensedPriceOnly ? (
    <div>Out of network</div>
  ) : (
    <>
      <div className="flex flex-wrap items-baseline">
        <div css={priceCss()}>Out of network</div>
      </div>
      <div css={priceExplanationCss}>
        <div>
          {isSingleProvider
            ? `${providerDisplayName ?? 'This provider'} doesn’t `
            : `These providers don’t `}
          take your {carrierName || 'insurance'} plan through Headway, so you
          won’t be able to book with them.
        </div>
        {!noLinks && (
          <div className="pb-3">
            If your insurance details have changed, you can edit them.
          </div>
        )}
      </div>
      {!noLinks && (
        <OpenInsuranceModalButton onClick={onClickUpdateInsuranceButton}>
          Change insurance details
        </OpenInsuranceModalButton>
      )}
    </>
  );
};

const ErrorOutOfNetworkRedirect = ({
  carrierName,
  condensedPriceOnly,
  providerDisplayNames,
  userSearchedStateMarket,
  noLinks = false,
  onClickUpdateInsuranceButton,
}: {
  carrierName?: string;
  condensedPriceOnly?: boolean;
  providerDisplayNames: string[];
  userSearchedStateMarket?: MarketRead;
  noLinks?: boolean;
  onClickUpdateInsuranceButton: () => void;
}) => {
  const isSingleProvider = providerDisplayNames.length === 1;
  const providerDisplayName = providerDisplayNames?.[0];

  return condensedPriceOnly ? (
    <div>Out of network</div>
  ) : (
    <>
      <div className="flex flex-wrap items-baseline">
        <div css={priceCss()}>Out of network</div>
      </div>
      <div css={priceExplanationCss}>
        {userSearchedStateMarket ? (
          <div>
            {isSingleProvider
              ? `${providerDisplayName ?? 'This provider'} doesn’t `
              : `These providers don’t `}
            take {carrierName || 'your insurance'} in{' '}
            {userSearchedStateMarket.displayName} through Headway, but there are
            lots of other providers who do.{' '}
            <ProvidersSearchButton
              carrierName={carrierName}
              stateMarket={userSearchedStateMarket}
            />
          </div>
        ) : null}
      </div>
      {!noLinks && (
        <>
          <div css={priceExplanationCss}>
            If your insurance details have changed, you can edit them.
          </div>
          <OpenInsuranceModalButton onClick={onClickUpdateInsuranceButton}>
            Change insurance details
          </OpenInsuranceModalButton>
        </>
      )}
    </>
  );
};

const ErrorOutOfStateRedirect = ({
  carrierName,
  condensedPriceOnly,
  providerDisplayNames,
  userSearchedStateMarket,
  noLinks = false,
  onClickUpdateInsuranceButton,
}: {
  carrierName?: string;
  condensedPriceOnly?: boolean;
  providerDisplayNames: string[];
  userSearchedStateMarket: MarketRead;
  noLinks?: boolean;
  onClickUpdateInsuranceButton: () => void;
}) => {
  const isSingleProvider = providerDisplayNames.length === 1;
  const providerDisplayName = providerDisplayNames?.[0];

  return condensedPriceOnly ? (
    <div>Provider out of state</div>
  ) : (
    <>
      <div className="flex flex-wrap items-baseline">
        <div css={priceCss()}>Out of state</div>
      </div>
      <div css={priceExplanationCss}>
        <div>
          You indicated you live in{' '}
          <strong>{userSearchedStateMarket.displayName}</strong>, but{' '}
          {isSingleProvider
            ? `${providerDisplayName ?? 'this provider'} isn't `
            : `these providers aren’t `}
          licensed to work with Headway in {userSearchedStateMarket.displayName}{' '}
          yet.{' '}
          <ProvidersSearchButton
            carrierName={carrierName}
            stateMarket={userSearchedStateMarket}
          />
        </div>
      </div>
      {!noLinks && (
        <>
          <div css={priceExplanationCss}>
            If your location has changed, you can edit it.
          </div>
          <OpenInsuranceModalButton onClick={onClickUpdateInsuranceButton}>
            Change my location
          </OpenInsuranceModalButton>
        </>
      )}
    </>
  );
};

const IntakeCallPrice = () => (
  <div className="flex flex-wrap items-baseline">
    <div css={priceCss()}>$0</div>
    <div
      css={{ paddingLeft: theme.space.xs2, paddingBottom: theme.space.base }}
    >
      for phone consultation
    </div>
  </div>
);

const ErrorOutOfState = ({
  condensedPriceOnly,
  providerDisplayNames,
  userSearchedStateMarket,
  noLinks = false,
  onClickUpdateInsuranceButton,
}: {
  condensedPriceOnly?: boolean;
  providerDisplayNames: string[];
  userSearchedStateMarket: MarketRead;
  noLinks?: boolean;
  onClickUpdateInsuranceButton: () => void;
}) => {
  const isSingleProvider = providerDisplayNames.length === 1;
  const providerDisplayName = providerDisplayNames?.[0];

  return condensedPriceOnly ? (
    <div>Provider out of state</div>
  ) : (
    <>
      <div className="flex flex-wrap items-baseline">
        <div css={priceCss()}>Out of state</div>
      </div>
      <div css={priceExplanationCss}>
        <div>
          You indicated to us you live in{' '}
          <strong>{userSearchedStateMarket.displayName}</strong>, but{' '}
          {isSingleProvider
            ? `${providerDisplayName ?? 'this provider'} isn't `
            : `these providers aren’t `}
          licensed to work with Headway in {userSearchedStateMarket.displayName}{' '}
          yet.
        </div>
      </div>
      {!noLinks && (
        <OpenInsuranceModalButton onClick={onClickUpdateInsuranceButton}>
          Change my state
        </OpenInsuranceModalButton>
      )}
    </>
  );
};

const NoStateView = ({
  condensedPriceOnly,
  firstSessionMaxPrice,
  isPhoneConsult,
  noLinks = false,
  onClickUpdateInsuranceButton,
}: {
  condensedPriceOnly?: boolean;
  firstSessionMaxPrice: number;
  isPhoneConsult?: boolean;
  noLinks?: boolean;
  onClickUpdateInsuranceButton: () => void;
}) => {
  const priceExplanation =
    'Your estimated cost might change as we learn more details about your coverage, such as whether you’ve met your deductible.';
  if (condensedPriceOnly) {
    return <div>$0-{firstSessionMaxPrice}/session</div>;
  }
  return (
    <>
      {isPhoneConsult && <IntakeCallPrice />}
      <div className="flex flex-wrap items-baseline">
        <div
          css={priceCss(isPhoneConsult ? false : undefined, theme.fontSize.xl)}
        >
          $0-{firstSessionMaxPrice}
        </div>
        <div className="pl-1">
          {isPhoneConsult ? 'for full sessions' : 'per session'}
        </div>
      </div>
      <div css={priceExplanationCss}>{priceExplanation}</div>
      {!noLinks && (
        <OpenInsuranceModalButton onClick={onClickUpdateInsuranceButton}>
          Add your state
        </OpenInsuranceModalButton>
      )}
    </>
  );
};

const HumanErrorView = ({
  user,
  isBookingStep,
  appointmentReadinessQuery,
  onClickUpdateInsuranceButton,
}: {
  user: UserRead;
  isBookingStep: boolean;
  appointmentReadinessQuery: UseQueryResult<
    UserAppointmentReadiness | undefined
  >;
  onClickUpdateInsuranceButton: () => void;
}) => {
  const mismatchedInputFields =
    (
      (appointmentReadinessQuery?.data?.insurance || []).find(
        (issue) =>
          issue.type ===
          InsuranceReadinessIssueInsufficientOrIncorrectInfoType.INSUFFICIENT_OR_INCORRECT_INFORMATION
      ) as InsuranceReadinessIssueInsufficientOrIncorrectInfo
    )?.patientMismatchInputFields || [];

  React.useEffect(() => {
    trackPageView({
      name: 'Inactive Insurance Messaging Viewed',
      properties: {
        lookupStatus: LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
        userId: user.id,
        confettiHumanInputErrorExperimentGroup: 'treatment',
        confettiHumanInputErrorSurfacedBy:
          mismatchedInputFields.length > 0 ? 'HW' : 'CHC',
      },
    });
  }, [user.id]);

  return mismatchedInputFields.length > 0 ? (
    <div
      css={{
        ...helixTheme.stack.vertical,
        alignItems: 'flex-start',
        color: helixTheme.color.system.black,
        gap: helixTheme.spacing.x1,
      }}
    >
      <div css={priceHeaderCss}>
        <IconWarningCircle
          css={{
            color: helixTheme.color.primary.red,
            minWidth: 20,
            minHeight: 20,
          }}
        />
        <SectionHeader>
          {isBookingStep
            ? 'Likely typo in insurance details'
            : getHumanInputErrorMismatchString(mismatchedInputFields)}
        </SectionHeader>
      </div>
      {isBookingStep ? (
        <>
          Check to make sure your insurance details match the details on your
          insurance card.
        </>
      ) : (
        <>
          <div>
            To book your session, please ensure your{' '}
            <Link href="/benefits" target="_blank" rel="noopener noreferrer">
              insurance details
            </Link>{' '}
            match the details on your insurance card. If you’re still seeing an
            error after updating, reach out to your insurer directly.
          </div>
          <OpenInsuranceModalButton
            onClick={() => {
              onClickUpdateInsuranceButton();
              trackEvent({
                name: 'Inactive Insurance Messaging CTA Clicked',
                properties: {
                  lookupStatus:
                    LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
                  userId: user.id,
                  confettiHumanInputErrorExperimentGroup: 'treatment',
                  confettiHumanInputErrorSurfacedBy:
                    mismatchedInputFields.length > 0 ? 'HW' : 'CHC',
                },
              });
            }}
          >
            Update insurance information
          </OpenInsuranceModalButton>
        </>
      )}
    </div>
  ) : (
    <div
      css={{
        ...helixTheme.stack.vertical,
        alignItems: 'flex-start',
        color: helixTheme.color.system.black,
        gap: helixTheme.spacing.x1,
      }}
    >
      <div css={priceHeaderCss}>
        <IconWarningCircle css={{ color: helixTheme.color.primary.red }} />
        <SectionHeader>Did you type that correctly?</SectionHeader>
      </div>
      <div>
        Please check for typos and confirm the details match the details on the
        insurance card for the client (the person receiving care). If everything
        matches, request a{' '}
        <Link
          href={getVerifyInsuranceBenefitsProviderRedirectUrl()}
          onClick={() => {
            trackEvent({
              name: 'Contact Form Link Clicked',
              properties: {
                lookupStatus:
                  LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
                userId: user.id,
                confettiHumanInputErrorExperimentGroup: 'treatment',
                confettiHumanInputErrorSurfacedBy:
                  mismatchedInputFields.length > 0 ? 'HW' : 'CHC',
              },
            });
          }}
        >
          manual verification
        </Link>
        .
      </div>
      <OpenInsuranceModalButton
        onClick={() => {
          onClickUpdateInsuranceButton();
          trackEvent({
            name: 'Inactive Insurance Messaging CTA Clicked',
            properties: {
              lookupStatus: LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
              userId: user.id,
              confettiHumanInputErrorExperimentGroup: 'treatment',
              confettiHumanInputErrorSurfacedBy:
                mismatchedInputFields.length > 0 ? 'HW' : 'CHC',
            },
          });
        }}
      >
        Update insurance information
      </OpenInsuranceModalButton>
    </div>
  );
};

const PriceEstimateByStatus = ({
  carrierName,
  condensedPriceOnly,
  firstSessionMinPrice,
  firstSessionMaxPrice,
  market,
  otherSessionsMinPrice,
  otherSessionsMaxPrice,
  applicableSessionCount,
  billingType,
  insuranceDetailsState,
  providerDisplayNames,
  availability,
  status,
  noLinks = false,
  shouldBlockInsuranceUploadAndBooking = false,
  lookupStatus,
  user,
  provider,
  isBookingStep,
  onClickUpdateInsuranceButton,
}: {
  carrierName?: string;
  condensedPriceOnly?: boolean;
  firstSessionMinPrice?: number;
  firstSessionMaxPrice?: number;
  market?: MarketRead;
  otherSessionsMinPrice?: number;
  otherSessionsMaxPrice?: number;
  applicableSessionCount?: number;
  billingType?: BillingType;
  insuranceDetailsState?: UserInsuranceDetailsState;
  providerDisplayNames: string[];
  availability?: AvailabilityEventResponse;
  status?: PricingCardStatus;
  noLinks?: boolean;
  shouldBlockInsuranceUploadAndBooking?: boolean;
  lookupStatus?: LookupStatus;
  user: UserRead;
  provider: ProviderRead;
  isBookingStep?: boolean;
  onClickUpdateInsuranceButton: () => void;
}) => {
  const providerPatients = user.providerPatients;
  const hasSeenProviderBefore =
    !!providerPatients &&
    providerPatients.some(
      (providerPatient) =>
        providerPatient.providerId === provider.id && !providerPatient.hidden
    );
  const pausedLookups =
    insuranceDetailsState === UserInsuranceDetailsState.NOT_ON_FILE_PAUSED;
  const hasFreeTherapy = status === PricingCardStatus.ZERO_DOLLAR_PRICE;
  const hasCopay =
    status === PricingCardStatus.COPAY_DUE &&
    firstSessionMinPrice !== undefined;
  const owesDeductible =
    status === PricingCardStatus.DEDUCTIBLE_REMAINING &&
    firstSessionMinPrice !== undefined &&
    otherSessionsMinPrice !== undefined;
  const owesDeductiblePrescriber =
    status === PricingCardStatus.DEDUCTIBLE_REMAINING_PRESCRIBER &&
    firstSessionMaxPrice !== undefined &&
    firstSessionMinPrice !== undefined &&
    otherSessionsMaxPrice !== undefined &&
    otherSessionsMinPrice !== undefined;
  const missingInfo =
    (status === PricingCardStatus.PARTIAL_INFORMATION ||
      status === PricingCardStatus.EMPTY_INFORMATION) &&
    firstSessionMaxPrice !== undefined;
  const errorINN = status === PricingCardStatus.ERROR_IN_NETWORK;
  const errorOON = status === PricingCardStatus.ERROR_OUT_OF_NETWORK;
  const owesDeductibleRange =
    status === PricingCardStatus.DEDUCTIBLE_REMAINING_RANGE &&
    firstSessionMinPrice !== undefined &&
    otherSessionsMaxPrice !== undefined &&
    otherSessionsMinPrice !== undefined;
  const redirectOON =
    status === PricingCardStatus.ERROR_OUT_OF_NETWORK_REDIRECT;
  const redirectState =
    status === PricingCardStatus.ERROR_OTHER_STATE_REDIRECT && market;
  const outOfState = status === PricingCardStatus.ERROR_OUT_OF_STATE && market;
  const missingStateInfo =
    status === PricingCardStatus.NO_STATE && firstSessionMaxPrice !== undefined;
  const isPhoneConsult =
    availability && availability.type === ProviderEventType.INTAKE_CALL;

  const isInNoDataOutage = user.activeUserInsurance?.isInNoDataOutage;
  const isInOldDataOutage = user.activeUserInsurance?.isInOldDataOutage;
  const isInOutage = user.activeUserInsurance?.isInOutage;

  const appointmentReadinessQuery = useAppointmentReadiness(user.id);
  const insuranceIssues = appointmentReadinessQuery?.data?.insurance;

  const isTerminatedUnready = hasTerminatedReadinessIssue(insuranceIssues);

  const noRemainingSessions =
    hasNoRemainingSessionReadinessIssue(insuranceIssues);

  const isNoMentalHealthBenefitsUnready =
    hasNoMentalHealthBenefitsReadinessIssue(insuranceIssues);

  const isOONUnready = hasOutOfNetworkReadinessIssue(insuranceIssues);

  const isManualVerificationUnready =
    hasManualVerificationRequiredReadinessIssue(insuranceIssues);

  const isTelehealthUnready =
    hasNoTelehealthBenefitsReadinessIssue(insuranceIssues);

  const hasTrackableReadinessIssue =
    getHasTrackableReadinessIssue(insuranceIssues);

  const lookupHasHumanError =
    lookupStatus === LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION;

  const [
    isIneligibilityExplainerModalOpen,
    setIsIneligibilityExplainerModalOpen,
  ] = React.useState<boolean>(false);

  useEffect(() => {
    if (
      user.activeUserInsurance &&
      (user.activeUserInsurance.isInOutage || hasTrackableReadinessIssue)
    ) {
      trackPageView({
        name: 'Ineligible Insurance Banner Viewed',
        properties: {
          patientUserId: user.id,
          userInsuranceId: String(user.activeUserInsurance.id),
          eligibilityLookupId:
            user.activeUserInsurance?.latestEligibilityLookup?.id,
          ineligibleLookupType: readinessIssuesToAvoIneligibleLookupType(
            appointmentReadinessQuery.data?.insurance
          ),
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
    }
  }, [user.activeUserInsurance, user.id, hasTrackableReadinessIssue]);

  useEffect(() => {
    if (
      !pausedLookups &&
      !hasFreeTherapy &&
      !hasCopay &&
      !owesDeductible &&
      !owesDeductiblePrescriber &&
      !missingInfo &&
      !errorINN &&
      !errorOON &&
      !owesDeductibleRange &&
      !redirectOON &&
      !redirectState &&
      !outOfState &&
      !missingStateInfo &&
      !lookupHasHumanError
    ) {
      logWarning('No price estimate view', {
        extra: {
          carrierName,
          firstSessionMinPrice,
          firstSessionMaxPrice,
          marketId: market?.id,
          otherSessionsMinPrice,
          otherSessionsMaxPrice,
          status,
        },
      });
    }
  }, [
    hasFreeTherapy,
    hasCopay,
    owesDeductible,
    owesDeductiblePrescriber,
    missingInfo,
    errorINN,
    errorOON,
    owesDeductibleRange,
    redirectOON,
    redirectState,
    outOfState,
    missingStateInfo,
    carrierName,
    firstSessionMinPrice,
    firstSessionMaxPrice,
    market?.id,
    otherSessionsMinPrice,
    otherSessionsMaxPrice,
    status,
    shouldBlockInsuranceUploadAndBooking,
    pausedLookups,
    lookupHasHumanError,
    isBookingStep,
    appointmentReadinessQuery,
  ]);

  useEffect(() => {
    const id = isUserRegistered(user) ? String(user.id) : undefined;

    identifyUser(id, {
      integrations: {
        All: true,
        Salesforce: false,
        'Google Analytics': false,
        'Google Analytics 4': false,
      },
    });
  }, [user.id]);

  let view: JSX.Element;

  if (isInOutage) {
    view = (
      <InsuranceOutageState
        carrierName={carrierName}
        firstSessionMinPrice={firstSessionMinPrice}
        firstSessionMaxPrice={firstSessionMaxPrice}
        isInNoDataOutage={isInNoDataOutage}
        isInOldDataOutage={isInOldDataOutage}
        onClickUpdateInsuranceButton={onClickUpdateInsuranceButton}
      />
    );
  } else if (lookupHasHumanError) {
    view = (
      <HumanErrorView
        user={user}
        isBookingStep={!!isBookingStep}
        appointmentReadinessQuery={appointmentReadinessQuery}
        onClickUpdateInsuranceButton={onClickUpdateInsuranceButton}
      />
    );
  } else if (isTerminatedUnready) {
    view = (
      <div className="flex flex-wrap items-baseline">
        <span>
          <IconWarningCircle
            color={helixTheme.color.primary.red}
            height={helixTheme.spacing.x5}
            width={helixTheme.spacing.x5}
          />{' '}
          <ContentText variant="section-title/medium">
            Your plan appears to no longer be active.{' '}
          </ContentText>
        </span>
        <ContentText>
          <HelixButton
            variant="link"
            type="submit"
            size="medium"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See more details and what you can do.
          </HelixButton>
        </ContentText>
      </div>
    );
  } else if (isNoMentalHealthBenefitsUnready) {
    view = (
      <div className="flex flex-wrap items-baseline">
        <span>
          <IconWarningCircle
            color={helixTheme.color.primary.red}
            height={helixTheme.spacing.x5}
            width={helixTheme.spacing.x5}
          />{' '}
          <ContentText variant="section-title/medium">
            No mental health coverage for this plan.{' '}
          </ContentText>
        </span>
        <ContentText>
          <HelixButton
            variant="link"
            type="submit"
            size="medium"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See more details and what you can do.
          </HelixButton>
        </ContentText>
      </div>
    );
  } else if (isOONUnready) {
    view = (
      <div className="flex flex-wrap items-baseline">
        <span>
          <IconWarningCircle
            color={helixTheme.color.primary.red}
            height={helixTheme.spacing.x5}
            width={helixTheme.spacing.x5}
          />{' '}
          <ContentText variant="section-title/medium">
            Headway’s agreement with {carrierName} does not currently include
            this plan
          </ContentText>
        </span>
        <ContentText>
          <HelixButton
            variant="link"
            type="submit"
            size="medium"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See more details and what you can do.
          </HelixButton>
        </ContentText>
      </div>
    );
  } else if (noRemainingSessions) {
    view = (
      <div className="flex flex-wrap items-baseline">
        <span>
          <IconWarningCircle
            color={helixTheme.color.primary.red}
            height={helixTheme.spacing.x5}
            width={helixTheme.spacing.x5}
          />{' '}
          <ContentText variant="section-title/medium">
            No remaining sessions
          </ContentText>
        </span>
        <ContentText>
          <HelixButton
            variant="link"
            type="submit"
            size="medium"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            Your plan covers a limited number of sessions per plan year. See
            more details.
          </HelixButton>
        </ContentText>
      </div>
    );
  } else if (isTelehealthUnready) {
    view = (
      <div className="flex flex-wrap items-baseline">
        <span>
          <IconWarningCircle
            color={helixTheme.color.primary.red}
            height={helixTheme.spacing.x5}
            width={helixTheme.spacing.x5}
          />{' '}
          <ContentText variant="section-title/medium">
            Virtual sessions not covered
          </ContentText>
        </span>
        <ContentText>
          You can switch to in-person or use private pay.
          <HelixButton
            variant="link"
            type="submit"
            size="medium"
            onPress={() => setIsIneligibilityExplainerModalOpen(true)}
          >
            See more details.
          </HelixButton>
        </ContentText>
      </div>
    );
  } else if (isManualVerificationUnready) {
    view = (
      <div className="flex flex-wrap items-baseline">
        <span>
          <IconWarningCircle
            color={helixTheme.color.primary.red}
            height={helixTheme.spacing.x5}
            width={helixTheme.spacing.x5}
          />{' '}
          <ContentText variant="section-title/medium">
            We can’t verify this plan
          </ContentText>
        </span>
        <div>
          <ContentText>We’ll need to do a manual verification. </ContentText>{' '}
          <ContentText>
            <HelixButton
              variant="link"
              type="submit"
              size="medium"
              onPress={() => setIsIneligibilityExplainerModalOpen(true)}
            >
              See details.
            </HelixButton>
          </ContentText>
        </div>
      </div>
    );
    // also add some IS EAP condition
  } else if (billingType === BillingType.EAP) {
    view = (
      <EAPPriceState
        condensedPriceOnly={condensedPriceOnly}
        isPhoneConsult={isPhoneConsult}
        applicableSessionCount={applicableSessionCount}
        providerDisplayName={providerDisplayNames[0]}
      />
    );
  } else if (hasFreeTherapy) {
    view = (
      <ZeroDollarPriceState
        condensedPriceOnly={condensedPriceOnly}
        isPhoneConsult={isPhoneConsult}
      />
    );
  } else if (pausedLookups) {
    view = PausedLookupState;
  } else if (hasCopay) {
    view = (
      <CopayDueState
        condensedPriceOnly={condensedPriceOnly}
        firstSessionMinPrice={firstSessionMinPrice}
        isPhoneConsult={isPhoneConsult}
        noLinks={noLinks}
      />
    );
  } else if (owesDeductible) {
    view = (
      <DeductibleRemainingState
        hasSeenProviderBefore={hasSeenProviderBefore}
        condensedPriceOnly={condensedPriceOnly}
        firstSessionMinPrice={firstSessionMinPrice}
        otherSessionsMinPrice={otherSessionsMinPrice}
        isPhoneConsult={isPhoneConsult}
        noLinks={noLinks}
      />
    );
  } else if (owesDeductiblePrescriber) {
    view = (
      <DeductibleRemainingPrescriberState
        hasSeenProviderBefore={hasSeenProviderBefore}
        condensedPriceOnly={condensedPriceOnly}
        firstSessionMaxPrice={firstSessionMaxPrice}
        firstSessionMinPrice={firstSessionMinPrice}
        otherSessionsMaxPrice={otherSessionsMaxPrice}
        otherSessionsMinPrice={otherSessionsMinPrice}
        providerDisplayNames={providerDisplayNames}
        isPhoneConsult={isPhoneConsult}
        noLinks={noLinks}
      />
    );
  } else if (missingInfo) {
    view = (
      <PartialInformationState
        condensedPriceOnly={condensedPriceOnly}
        isPhoneConsult={isPhoneConsult}
        noLinks={noLinks}
        onClickUpdateInsuranceButton={onClickUpdateInsuranceButton}
      />
    );
  } else if (errorINN) {
    view = <ErrorInNetworkState condensedPriceOnly={condensedPriceOnly} />;
  } else if (errorOON) {
    view = (
      <ErrorOutOfNetwork
        carrierName={carrierName}
        condensedPriceOnly={condensedPriceOnly}
        providerDisplayNames={providerDisplayNames}
        onClickUpdateInsuranceButton={onClickUpdateInsuranceButton}
        noLinks={noLinks}
      />
    );
  } else if (owesDeductibleRange) {
    view = (
      <DeductibleRemainingRangeState
        hasSeenProviderBefore={hasSeenProviderBefore}
        condensedPriceOnly={condensedPriceOnly}
        firstSessionMinPrice={firstSessionMinPrice}
        otherSessionsMaxPrice={otherSessionsMaxPrice}
        otherSessionsMinPrice={otherSessionsMinPrice}
        isPhoneConsult={isPhoneConsult}
        noLinks={noLinks}
      />
    );
  } else if (redirectOON) {
    view = (
      <ErrorOutOfNetworkRedirect
        carrierName={carrierName}
        condensedPriceOnly={condensedPriceOnly}
        providerDisplayNames={providerDisplayNames}
        userSearchedStateMarket={market}
        noLinks={noLinks}
        onClickUpdateInsuranceButton={onClickUpdateInsuranceButton}
      />
    );
  } else if (redirectState) {
    view = (
      <ErrorOutOfStateRedirect
        carrierName={carrierName}
        condensedPriceOnly={condensedPriceOnly}
        providerDisplayNames={providerDisplayNames}
        userSearchedStateMarket={market}
        noLinks={noLinks}
        onClickUpdateInsuranceButton={onClickUpdateInsuranceButton}
      />
    );
  } else if (outOfState) {
    view = (
      <ErrorOutOfState
        condensedPriceOnly={condensedPriceOnly}
        providerDisplayNames={providerDisplayNames}
        userSearchedStateMarket={market}
        noLinks={noLinks}
        onClickUpdateInsuranceButton={onClickUpdateInsuranceButton}
      />
    );
  } else if (missingStateInfo) {
    view = (
      <NoStateView
        condensedPriceOnly={condensedPriceOnly}
        firstSessionMaxPrice={firstSessionMaxPrice}
        isPhoneConsult={isPhoneConsult}
        noLinks={noLinks}
        onClickUpdateInsuranceButton={onClickUpdateInsuranceButton}
      />
    );
  } else {
    view = condensedPriceOnly ? (
      <div>Refresh page for price</div>
    ) : (
      <div className="flex flex-wrap items-baseline">
        <div css={priceCss()}>Refresh page for price</div>
        <div css={priceExplanationCss}>
          We couldn’t find your insurance information. Please refresh the page
          to try again.
        </div>
      </div>
    );
  }
  return (
    <>
      <InsuranceIneligibilityExplainerModal
        user={user}
        open={isIneligibilityExplainerModalOpen}
        onClose={() => setIsIneligibilityExplainerModalOpen(false)}
        issues={appointmentReadinessQuery.data?.insurance || []}
      />
      {view}
    </>
  );
};

/* Styles */
const priceCss = (bold = true, fontSize: string = theme.fontSize.xl2) => css`
  color: ${theme.color.black};
  font-family: ${theme.fontFamily.postGrotesk};
  font-size: ${fontSize};
  font-weight: ${bold ? theme.fontWeight.bold : theme.fontWeight.base};
  margin: 0;
`;
const priceExplanationCss = css`
  font-size: ${theme.fontSize.sm};
  padding-top: ${theme.space.base};
`;
const priceCtaCss = css`
  letter-spacing: normal;
  margin-left: -${theme.space.xs};
`;

const textButtonCss = css`
  color: ${theme.color.primary};
  cursor: pointer;
  font-size: ${theme.fontSize.sm};
  margin-top: ${theme.space.xs};
`;

// HELIX STYLES
const priceHeaderCss = css`
  ${helixTheme.stack.horizontal};
  align-items: center;
  gap: ${helixTheme.spacing.x2};
`;

export { PriceEstimateByStatus };
