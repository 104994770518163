/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { PatientInsuranceStatusResponse } from '../models/PatientInsuranceStatusResponse';
import { ProviderPatientInsuranceStatusResponse } from '../models/ProviderPatientInsuranceStatusResponse';
import { UnitedStates } from '../models/UnitedStates';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class PatientInsuranceStatusApiResource {

    /**
     * Get Patient Insurance Status
     * Get a patient\&#39;s insurance status with Headway, regardless of provider. This will be used in combination with the provider patient insurance status to determine what experience to show to the provider regarding their clients\&#39; insurance statuses.  :param auth: :param patient_user_id:
     * @param patientUserId 
     
     */
    public getPatientInsuranceStatus(patientUserId: number, axiosConfig?: AxiosRequestConfig): Promise<PatientInsuranceStatusResponse> {
        const apiRoute = '/patients/{patient_user_id}/insurance-status';
        const reqPath = apiRoute
                    .replace('{' + 'patient_user_id}', String(patientUserId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Patient Insurance Status
     * Get a patient\&#39;s insurance status with a provider.  :param auth: :param patient_user_id: :param provider_id: :param appointment_id: Appointment id for an appointment with this patient and provider. If appointment_id is                         specified, is_telehealth_appointment and appointment_state are ignored. :param is_telehealth_appointment: Whether we\&#39;re checking for a telehealth appointment. :param appointment_state: Which US state the appointment would be in.
     * @param patientUserId 
     * @param providerId 
     * @param appointmentId 
     * @param isTelehealthAppointment 
     * @param appointmentState 
     * @param includeHiddenCarriers 
     * @param checkAppointmentState 
     
     */
    public getProviderPatientInsuranceStatus(patientUserId: number, providerId: number, query?: { appointment_id?: number, is_telehealth_appointment?: boolean, appointment_state?: UnitedStates, include_hidden_carriers?: boolean, check_appointment_state?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<ProviderPatientInsuranceStatusResponse> {
        const apiRoute = '/providers/{provider_id}/patients/{patient_user_id}/insurance-status';
        const reqPath = apiRoute
                    .replace('{' + 'patient_user_id}', String(patientUserId))
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const PatientInsuranceStatusApi = new PatientInsuranceStatusApiResource();
