import { ProviderHighlightKey } from '@headway/api/models/ProviderHighlightKey';

import PricingCardStatus from '../Providers/PricingCard/PricingCardStatus';
import { BookingPageLeftColumnContentVariants } from './BookingPageV2/layout';

export enum BookAppointmentStep {
  ACCOUNT = 'ACCOUNT',
  ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT',
  CONFLICT = 'CONFLICT',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  INSURANCE = 'INSURANCE',
  EAP = 'EAP',
  CHECKOUT = 'CHECKOUT',
  RESTRICTION = 'RESTRICTION',
  RESTRICTION_VIRTUAL_ONLY = 'RESTRICTION_VIRTUAL_ONLY',
  SUCCESS = 'SUCCESS',
  TELEHEALTH = 'TELEHEALTH',
  EAP_CHECKOUT = 'EAP_CHECKOUT',
}

export const ACCOUNT_STEP_HIGHLIGHTS = [
  ProviderHighlightKey.RARE_FIND,
  ProviderHighlightKey.NUM_AVAILABILITIES_THIS_WEEK,
  ProviderHighlightKey.LOYAL_CLIENTS,
  ProviderHighlightKey.EXPERIENCED,
];

export const STEPS_WITH_DESKTOP_PROGRESS_BAR: BookAppointmentStep[] = [
  BookAppointmentStep.INSURANCE,
  BookAppointmentStep.TELEHEALTH,
  BookAppointmentStep.CHECKOUT,
  BookAppointmentStep.ACKNOWLEDGEMENT,
  BookAppointmentStep.SUCCESS,
  BookAppointmentStep.EAP,
  BookAppointmentStep.EAP_CHECKOUT,
];

export const STEPS_WITH_DESKTOP_PROGRESS_BAR_V2: BookAppointmentStep[] = [
  BookAppointmentStep.INSURANCE,
  BookAppointmentStep.TELEHEALTH,
  BookAppointmentStep.CHECKOUT,
  BookAppointmentStep.ACKNOWLEDGEMENT,
  BookAppointmentStep.EAP,
  BookAppointmentStep.EAP_CHECKOUT,
  BookAppointmentStep.EMAIL_VERIFICATION,
];

export const STEPS_WITH_MOBILE_PROGRESS_BAR_V2: BookAppointmentStep[] = [
  BookAppointmentStep.INSURANCE,
  BookAppointmentStep.TELEHEALTH,
  BookAppointmentStep.CHECKOUT,
  BookAppointmentStep.ACKNOWLEDGEMENT,
  BookAppointmentStep.EAP,
  BookAppointmentStep.EAP_CHECKOUT,
  BookAppointmentStep.EMAIL_VERIFICATION,
];

export const STEPS_WITH_DESKTOP_BACK_BUTTON: BookAppointmentStep[] = [
  BookAppointmentStep.ACCOUNT,
  BookAppointmentStep.EMAIL_VERIFICATION,
  BookAppointmentStep.INSURANCE,
  BookAppointmentStep.EAP,
  BookAppointmentStep.TELEHEALTH,
  BookAppointmentStep.ACKNOWLEDGEMENT,
  BookAppointmentStep.CHECKOUT,
  BookAppointmentStep.EAP_CHECKOUT,
  BookAppointmentStep.SUCCESS,
];

export const STEPS_WITH_MOBILE_BACK_BUTTON: BookAppointmentStep[] = [
  BookAppointmentStep.ACCOUNT,
  BookAppointmentStep.EMAIL_VERIFICATION,
  BookAppointmentStep.INSURANCE,
  BookAppointmentStep.EAP,
  BookAppointmentStep.TELEHEALTH,
  BookAppointmentStep.ACKNOWLEDGEMENT,
  BookAppointmentStep.CHECKOUT,
  BookAppointmentStep.EAP_CHECKOUT,
  BookAppointmentStep.SUCCESS,
];

/**
 * These booking steps are 'terminal', in other words the final step that a user will see in their booking journey.
 */
export const TERMINAL_BOOKING_STEPS = [
  BookAppointmentStep.CONFLICT,
  BookAppointmentStep.RESTRICTION,
  BookAppointmentStep.RESTRICTION_VIRTUAL_ONLY,
  BookAppointmentStep.SUCCESS,
];

export const LEFT_COLUMN_CONTENT_VARIANT_BY_STEP: Record<
  BookAppointmentStep,
  BookingPageLeftColumnContentVariants | undefined
> = {
  [BookAppointmentStep.ACCOUNT]:
    BookingPageLeftColumnContentVariants.HeadwayLetter,
  [BookAppointmentStep.ACKNOWLEDGEMENT]:
    BookingPageLeftColumnContentVariants.UnconfirmedAppointmentCard,
  [BookAppointmentStep.CHECKOUT]:
    BookingPageLeftColumnContentVariants.UnconfirmedAppointmentCard,
  [BookAppointmentStep.CONFLICT]: undefined,
  [BookAppointmentStep.EAP]:
    BookingPageLeftColumnContentVariants.UnconfirmedAppointmentCard,
  [BookAppointmentStep.EMAIL_VERIFICATION]:
    BookingPageLeftColumnContentVariants.HeadwayLetter,
  [BookAppointmentStep.INSURANCE]:
    BookingPageLeftColumnContentVariants.UnconfirmedAppointmentCard,
  [BookAppointmentStep.RESTRICTION]: undefined,
  [BookAppointmentStep.RESTRICTION_VIRTUAL_ONLY]: undefined,
  [BookAppointmentStep.SUCCESS]:
    BookingPageLeftColumnContentVariants.ConfirmedAppointmentCard,
  [BookAppointmentStep.TELEHEALTH]:
    BookingPageLeftColumnContentVariants.UnconfirmedAppointmentCard,
  [BookAppointmentStep.EAP_CHECKOUT]:
    BookingPageLeftColumnContentVariants.UnconfirmedAppointmentCard,
};

export const PRICING_CARD_STATUSES_WITH_SAVINGS_CARD_CONTENT: PricingCardStatus[] =
  [
    PricingCardStatus.COPAY_DUE,
    PricingCardStatus.ZERO_DOLLAR_PRICE,
    PricingCardStatus.DEDUCTIBLE_REMAINING,
    PricingCardStatus.DEDUCTIBLE_REMAINING_PRESCRIBER,
    PricingCardStatus.DEDUCTIBLE_REMAINING_RANGE,
  ];

export const DATADOG_RUM_PREFIX = '[New Checkout Flow]';

export const RUDDERSTACK_CHECKOUT_REDESIGN_EXPERIMENT_NAME =
  'AGORA_CHECKOUT_REDESIGN';
