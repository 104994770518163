/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum PatientMismatchInputField {
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    DOB = 'DOB',
    MEMBER_ID = 'MEMBER_ID'
}


export const PatientMismatchInputFieldMetadata: EnumMetadata<PatientMismatchInputField> = {
    name: 'PatientMismatchInputField',
    values: {
            FIRST_NAME: 'FIRST_NAME',
            LAST_NAME: 'LAST_NAME',
            DOB: 'DOB',
            MEMBER_ID: 'MEMBER_ID'
    }
}
