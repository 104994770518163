import { datadogRum } from '@datadog/browser-rum';
import { css } from '@emotion/react';
import Skeleton from '@mui/material/Skeleton';
import { useSearchParams } from 'next/navigation';
import React, { useState } from 'react';
import { UrlObject } from 'url';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { InsuranceReadinessIssueInsufficientOrIncorrectInfoType } from '@headway/api/models/InsuranceReadinessIssueInsufficientOrIncorrectInfo';
import { LookupStatus } from '@headway/api/models/LookupStatus';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserInsuranceDetailsState } from '@headway/api/models/UserInsuranceDetailsState';
import { UserPriceEstimateErrorState } from '@headway/api/models/UserPriceEstimateErrorState';
import { UserRead } from '@headway/api/models/UserRead';
import { UserSessionLocationPreference } from '@headway/api/models/UserSessionLocationPreference';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { useAppointmentReadiness } from '@headway/shared/hooks/useAppointmentReadiness';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { useActiveUserFreezes } from '@headway/shared/hooks/useUserFreezes';
import { useQuery } from '@headway/shared/react-query';
import { BookingAvailabilityEventSelection } from '@headway/shared/types/booking';
import { patientCanOnlySeeVirtualAppts } from '@headway/shared/utils/insuranceUtils';
import { getProviderDisplayFirstAndLastWithPrenomial } from '@headway/shared/utils/providers';
import { logException } from '@headway/shared/utils/sentry';
import { useMarkets } from '@headway/ui/providers/MarketProvider';
import { theme } from '@headway/ui/theme';
import { notifyError, notifyWarning } from '@headway/ui/utils/notify';

import { useRouter } from '../../../hooks/useRouter';
import { IAuthStore, IUiStore, withStores } from '../../../stores/withStores';
import { useCarriersAndProviderPriceQuery } from '../../BookAppointmentFlow/queries';
import InsuranceLookupModal from '../../InsuranceLookupModal/InsuranceLookupModal';
import { BookingAvailability } from '../BookingModal/BookingAvailability';
import { trackPricingCardStatus } from '../PricingCard/PricingCard';
import { getPricingCardStatusFromPricingDetails } from '../PricingCard/utils';
import {
  trackAvailabilitySlotSelected,
  trackSelectAvailabilityCompleted,
  trackSelectAvailabilityStarted,
} from './analyticsEvents';
import { PriceEstimateByStatus } from './PriceEstimateByStatus';
import { formSectionTitleCss } from './Text';

interface AvailabilityBookingProps {
  addresses: ProviderAddressRead[];
  AuthStore: IAuthStore;
  daysPerPage?: number;
  provider: ProviderRead;
  UiStore: IUiStore;
  sessionId?: number;
  patientUser: UserRead;
}

interface PriceEstimateProps {
  AuthStore: IAuthStore;
  className?: string;
  condensedPriceOnly?: boolean;
  provider: ProviderRead;
  UiStore: IUiStore;
  availability?: AvailabilityEventResponse;
  noDivider?: boolean;
  noLinks?: boolean;
  isBookingStep?: boolean;
}

interface ProviderPriceAndAvailabilityProps {
  addresses: ProviderAddressRead[];
  AuthStore: IAuthStore;
  availabilityDaysPerPage?: number;
  provider: ProviderRead;
  UiStore: IUiStore;
  sessionId?: number;
}

const AvailabilityBooking = ({
  addresses,
  AuthStore,
  daysPerPage,
  provider,
  UiStore,
  sessionId,
  patientUser,
}: AvailabilityBookingProps) => {
  // this is used purely for deciding whether to fire an analytics event.
  // because we automatically select an availability for the user when we first load
  // the availability data, we only want to fire the analytics event when the user
  // manually selects an availability.
  const [hasManuallySelectedAvailability, setHasManuallySelectedAvailability] =
    useState(false);

  const searchParams = useSearchParams();
  const mustBeVirtual = patientCanOnlySeeVirtualAppts(
    patientUser,
    provider.providerFrontEndCarriers
  );

  const providerDisplayName =
    getProviderDisplayFirstAndLastWithPrenomial(provider);

  const router = useRouter();

  return (
    <>
      <div css={formSectionTitleCss}>
        When would you like to meet
        {providerDisplayName ? ` ${providerDisplayName}` : ''}?
      </div>
      <BookingAvailability
        addresses={addresses}
        daysPerPage={daysPerPage}
        onAvailabilitySlotClick={(autoSelected) => {
          datadogRum.addAction(
            `Booking Flow - Select availability slot on provider profile`
          );
          if (!hasManuallySelectedAvailability && !autoSelected) {
            trackSelectAvailabilityStarted({});
            setHasManuallySelectedAvailability(true);
          }
          trackAvailabilitySlotSelected({
            autoSelected,
          });
        }}
        onError={(message: string) => notifyWarning(message)}
        onNext={(availabilityEvent) => {
          datadogRum.addAction(
            `Booking Flow - Continued to booking from provider profile`
          );
          trackSelectAvailabilityCompleted({});
          const searchSessionId = router.query?.sessionId as string;

          const bookingUrl = getAvailabilityBookingUrl({
            availability: availabilityEvent,
            slug: provider.slug,
            searchSessionId: searchSessionId,
            algoliaQueryId: searchParams.get('queryId') || '',
          });
          router.push(bookingUrl);
        }}
        provider={provider}
        user={AuthStore.user}
        sessionId={sessionId}
        mustBeVirtual={mustBeVirtual}
      />
    </>
  );
};

const PriceEstimateImpl = ({
  className,
  condensedPriceOnly = false,
  provider,
  availability,
  noDivider = false,
  noLinks = false,
  isBookingStep = false,
  AuthStore,
}: PriceEstimateProps) => {
  const { liveMarkets, marketsByState } = useMarkets();
  const [showInsuranceLookupModal, setShowInsuranceLookupModal] =
    useState(false);
  const { user: patientUser } = AuthStore;
  const { frontEndCarriers: carriers, carriersById } = useFrontEndCarriers();

  const providerPriceQuery = useCarriersAndProviderPriceQuery({
    providerId: provider.id,
    patientUserId: patientUser.id,
    activeUserInsuranceId: patientUser.activeUserInsuranceId,
    options: {
      select: (data) => data[1],
      onSettled: (priceEstimate, error) => {
        if (error) {
          trackPricingCardStatus(
            {
              insuranceDetailsState: UserInsuranceDetailsState.NOT_ON_FILE,
              errorState: UserPriceEstimateErrorState.COULD_NOT_CALCULATE,
            },
            provider,
            patientUser,
            liveMarkets
          );
        } else if (priceEstimate && carriers) {
          const userCarrier = patientUser.activeUserInsurance
            ? carriersById[patientUser.activeUserInsurance.frontEndCarrierId]
            : undefined;
          trackPricingCardStatus(
            priceEstimate,
            provider,
            patientUser,
            liveMarkets,
            userCarrier
          );
        }
      },
    },
  });

  // https://tkdodo.eu/blog/react-query-error-handling#the-onerror-callback
  React.useEffect(() => {
    if (!providerPriceQuery.error) return;

    notifyError('Error getting your price'); // TODO(ldthorne): copy
    logException(providerPriceQuery.error, {
      extra: { providerId: provider.id },
    });
  }, [providerPriceQuery.error, provider.id]);

  const priceEstimate = providerPriceQuery.data;
  const {
    firstSessionMinPrice,
    firstSessionMaxPrice,
    otherSessionsMinPrice,
    otherSessionsMaxPrice,
    applicableSessionCount,
    billingType,
    insuranceDetailsState,
  } = priceEstimate || {};

  const appointmentReadinessQuery = useAppointmentReadiness(patientUser.id);

  const userCarrier = patientUser.activeUserInsurance
    ? carriersById[patientUser.activeUserInsurance!.frontEndCarrierId]
    : undefined;
  const status =
    carriers && priceEstimate
      ? getPricingCardStatusFromPricingDetails(
          priceEstimate,
          provider.isPrescriber,
          liveMarkets,
          patientUser.lastSearchedState,
          userCarrier,
          patientUser.activeUserInsurance,
          appointmentReadinessQuery.data
        )
      : undefined;
  const market = patientUser.lastSearchedState
    ? marketsByState[patientUser.lastSearchedState]
    : undefined;

  const { data: userFreezeReasons = [] } = useActiveUserFreezes(patientUser.id);

  const lookupHasHumanError = (
    appointmentReadinessQuery.data?.insurance || []
  ).find(
    (issue) =>
      issue.type ===
      InsuranceReadinessIssueInsufficientOrIncorrectInfoType.INSUFFICIENT_OR_INCORRECT_INFORMATION
  )
    ? LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION
    : undefined;

  if (userFreezeReasons.length > 0) {
    // hide price info if the user is frozen (they won't be able to book an appointment.)
    return <></>;
  }

  return (
    <>
      {providerPriceQuery.isLoading && condensedPriceOnly ? (
        <div className={className}>
          <Skeleton width="100px" />
        </div>
      ) : providerPriceQuery.isLoading ? (
        <div className={className} css={priceEstimateCss(noDivider)}>
          <Skeleton height={theme.space.xl2} width="50%" />
          <Skeleton height={theme.space.xl2} width="100%" />
        </div>
      ) : (
        <div
          className={className}
          css={condensedPriceOnly ? {} : priceEstimateCss(noDivider)}
        >
          <PriceEstimateByStatus
            carrierName={patientUser.activeUserInsurance?.frontEndCarrierName}
            condensedPriceOnly={condensedPriceOnly}
            firstSessionMinPrice={firstSessionMinPrice}
            firstSessionMaxPrice={firstSessionMaxPrice}
            market={market}
            otherSessionsMinPrice={otherSessionsMinPrice}
            otherSessionsMaxPrice={otherSessionsMaxPrice}
            applicableSessionCount={applicableSessionCount}
            billingType={billingType}
            insuranceDetailsState={insuranceDetailsState}
            providerDisplayNames={[provider.displayName]}
            availability={availability}
            status={status}
            noLinks={noLinks}
            lookupStatus={lookupHasHumanError}
            user={patientUser}
            provider={provider}
            isBookingStep={!!isBookingStep}
            onClickUpdateInsuranceButton={() =>
              setShowInsuranceLookupModal(true)
            }
          />
        </div>
      )}
      <InsuranceLookupModal
        providerId={provider.id}
        onClose={async (args) => {
          setShowInsuranceLookupModal(false);
          await providerPriceQuery.refetch();
        }}
        open={showInsuranceLookupModal}
      />
    </>
  );
};

export const PriceEstimate = withStores(PriceEstimateImpl);

/**
 * Displays the patient's out-of-pocket price and the provider's availability with
 * a CTA to book a session.
 */
const ProviderPriceAndAvailabilityImpl = ({
  addresses,
  AuthStore,
  availabilityDaysPerPage,
  provider,
  UiStore,
  sessionId,
}: ProviderPriceAndAvailabilityProps) => {
  const patientUser = AuthStore.user;
  return (
    <div css={containerCss}>
      <AvailabilityBooking
        addresses={addresses}
        AuthStore={AuthStore}
        daysPerPage={availabilityDaysPerPage}
        provider={provider}
        UiStore={UiStore}
        sessionId={sessionId}
        patientUser={patientUser}
      />
      <PriceEstimate provider={provider} />
    </div>
  );
};

/* Styles */

const containerCss = css`
  border: 1px solid ${theme.color.border};
  border-radius: 8px;
  // prettier-ignore
  padding: ${theme.space.xl2} ${theme.space.xl2} ${theme.space.lg} ${theme.space
    .xl2};
`;

const priceEstimateCss = (noDivider: boolean | undefined) => css`
  color: ${theme.color.textGray};
  margin-bottom: ${theme.space.base};
  border-top: ${noDivider ? `none` : `1px solid ${theme.color.border}`};
  padding-top: ${theme.space.base};
`;

export const ProviderPriceAndAvailability = withStores(
  ProviderPriceAndAvailabilityImpl
);

interface BookAvailabilityQueryParamsIntake {
  type: ProviderEventType.INTAKE_CALL;
  startDate: string;
}
interface BookAvailabilityQueryParamsInPerson {
  type: ProviderEventType.APPOINTMENT;
  startDate: string;
  locationPreference: UserSessionLocationPreference.IN_PERSON;
  providerAddressId: number;
}
interface BookAvailabilityQueryParamsVirtual {
  type: ProviderEventType.APPOINTMENT;
  startDate: string;
  locationPreference: UserSessionLocationPreference.VIRTUAL;
}

export type BookAvailabilityQueryParams =
  | BookAvailabilityQueryParamsIntake
  | BookAvailabilityQueryParamsInPerson
  | BookAvailabilityQueryParamsVirtual;

/*
  isIntakeCallParams, isInPersonParams, and isVirtualParams are
  typeguard helper functions to determine the type of availability
  the patient is searching for/trying to book
*/
export function isIntakeCallParams(
  params: unknown
): params is BookAvailabilityQueryParamsIntake {
  return (
    params instanceof Object &&
    'type' in params &&
    params.type === ProviderEventType.INTAKE_CALL &&
    'startDate' in params &&
    typeof params.startDate === 'string'
  );
}

export function isInPersonParams(
  params: unknown
): params is BookAvailabilityQueryParamsInPerson {
  return (
    params instanceof Object &&
    'type' in params &&
    params.type === ProviderEventType.APPOINTMENT &&
    'startDate' in params &&
    typeof params.startDate === 'string' &&
    'locationPreference' in params &&
    params.locationPreference === UserSessionLocationPreference.IN_PERSON &&
    'providerAddressId' in params &&
    typeof params.providerAddressId === 'number'
  );
}

export function isVirtualParams(
  params: unknown
): params is BookAvailabilityQueryParamsVirtual {
  return (
    params instanceof Object &&
    'type' in params &&
    params.type === ProviderEventType.APPOINTMENT &&
    'startDate' in params &&
    typeof params.startDate === 'string' &&
    'locationPreference' in params &&
    params.locationPreference === UserSessionLocationPreference.VIRTUAL
  );
}
/* end of typeguard helper functions */

export function getAvailabilityBookingUrl({
  availability,
  slug,
  searchSessionId,
  algoliaQueryId,
}: {
  availability: BookingAvailabilityEventSelection;
  slug: string;
  searchSessionId?: string;
  algoliaQueryId?: string;
}): UrlObject {
  const pathname = `/providers/${slug}/book`;

  let query = {};

  // See BookingAvailability form onSubmit to see availabilityEvent fields
  if (availability.type === ProviderEventType.INTAKE_CALL) {
    const params: BookAvailabilityQueryParamsIntake = {
      type: ProviderEventType.INTAKE_CALL,
      startDate: availability.startDate,
    };
    query = { ...params };
  } else if (!!availability.providerAddressId) {
    const params: BookAvailabilityQueryParamsInPerson = {
      type: ProviderEventType.APPOINTMENT,
      startDate: availability.startDate,
      locationPreference: UserSessionLocationPreference.IN_PERSON,
      providerAddressId: availability.providerAddressId,
    };
    query = { ...params };
  } else {
    const params: BookAvailabilityQueryParamsVirtual = {
      type: ProviderEventType.APPOINTMENT,
      startDate: availability.startDate,
      locationPreference: UserSessionLocationPreference.VIRTUAL,
    };
    query = { ...params };
  }

  if (typeof searchSessionId === 'string' && !!searchSessionId) {
    query = { ...query, searchSessionId: searchSessionId };
  }

  if (typeof algoliaQueryId === 'string' && !!algoliaQueryId) {
    query = { ...query, queryId: algoliaQueryId };
  }

  return {
    pathname,
    query,
  };
}
