/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum ProviderHighlightKey {
    AVAILABLE_THIS_WEEK = 'AVAILABLE_THIS_WEEK',
    NUM_AVAILABILITIES_THIS_WEEK = 'NUM_AVAILABILITIES_THIS_WEEK',
    RARE_FIND = 'RARE_FIND',
    PRESCRIBER = 'PRESCRIBER',
    IN_PERSON = 'IN_PERSON',
    PHONE_CONSULTS = 'PHONE_CONSULTS',
    EXPERIENCED = 'EXPERIENCED',
    LOYAL_CLIENTS = 'LOYAL_CLIENTS'
}


export const ProviderHighlightKeyMetadata: EnumMetadata<ProviderHighlightKey> = {
    name: 'ProviderHighlightKey',
    values: {
            AVAILABLE_THIS_WEEK: 'AVAILABLE_THIS_WEEK',
            NUM_AVAILABILITIES_THIS_WEEK: 'NUM_AVAILABILITIES_THIS_WEEK',
            RARE_FIND: 'RARE_FIND',
            PRESCRIBER: 'PRESCRIBER',
            IN_PERSON: 'IN_PERSON',
            PHONE_CONSULTS: 'PHONE_CONSULTS',
            EXPERIENCED: 'EXPERIENCED',
            LOYAL_CLIENTS: 'LOYAL_CLIENTS'
    }
}
