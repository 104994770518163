import { ClientIssueType } from '@headway/api/models/ClientIssueType';
import { CustomerType } from '@headway/api/models/CustomerType';

export const getVerifyInsuranceBenefitsProviderRedirectUrl = () => {
  let queryStringClient = new URLSearchParams();
  queryStringClient.append('submitterType', CustomerType.CLIENT);
  queryStringClient.append(
    'primaryIssueType',
    ClientIssueType.BILLING_INSURANCE_ISSUES
  );

  return `/contact?${queryStringClient.toString()}`;
};
