import { datadogRum } from '@datadog/browser-rum';
import max from 'lodash/max';
import moment from 'moment';
import { ParsedUrlQuery } from 'querystring';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderSpecialtyBookingPreference } from '@headway/api/models/ProviderSpecialtyBookingPreference';
import { UserClaimReadinessResponse } from '@headway/api/models/UserClaimReadinessResponse';
import { UserPriceEstimateResponse } from '@headway/api/models/UserPriceEstimateResponse';
import { UserRead } from '@headway/api/models/UserRead';
import { UserSessionLocationPreference } from '@headway/api/models/UserSessionLocationPreference';
import { isOutOfState } from '@headway/shared/utils/insuranceUtils';
import { getProviderActiveAndLiveStates } from '@headway/shared/utils/providers';
import { logWarning } from '@headway/shared/utils/sentry';
import { isValidEnumValue } from '@headway/shared/utils/types';
import {
  getVerificationStatus,
  UserVerificationStatus,
} from '@headway/shared/utils/userVerificationStatus';

import PricingCardStatus from '../Providers/PricingCard/PricingCardStatus';
import { AvailabilitySelectionReason } from './BookAppointmentAvailability';
import {
  PriceBreakdownProps,
  PriceEstimateSavingsCardProps,
} from './components/PriceEstimateSavingsCard';
import {
  BookAppointmentStep,
  DATADOG_RUM_PREFIX,
  PRICING_CARD_STATUSES_WITH_SAVINGS_CARD_CONTENT,
  TERMINAL_BOOKING_STEPS,
} from './constants';

const isTerminalStep = (step: BookAppointmentStep) => {
  return TERMINAL_BOOKING_STEPS.includes(step);
};

const getInitialBookAppointmentStep = ({
  user,
  provider,
  appointment,
  availability,
  insuranceReady,
  bookingPreference,
  shouldShowAnthemEAPExperience,
  isEAPReady,
}: {
  user: UserRead;
  provider: ProviderRead;
  appointment?: ConcreteProviderEventRead;
  availability: AvailabilityEventResponse | null;
  insuranceReady: boolean;
  bookingPreference: ProviderSpecialtyBookingPreference;
  shouldShowAnthemEAPExperience: boolean;
  isEAPReady: boolean;
}) => {
  const accountStatus = getVerificationStatus(user);

  const appointmentInNonBookableRange = isAppointmentInNonBookableRange({
    availability: availability,
    provider: provider,
  });

  const appointmentTypeMismatch = isAppointmentTypeMismatch({
    availability: availability,
    bookingPreference: bookingPreference,
  });

  // Function to get the starting point for a user when the page loads
  if (
    !availability ||
    appointmentInNonBookableRange ||
    appointmentTypeMismatch
  ) {
    return BookAppointmentStep.CONFLICT;
  } else if (accountStatus === UserVerificationStatus.UNREGISTERED) {
    return BookAppointmentStep.ACCOUNT;
  } else if (accountStatus === UserVerificationStatus.REGISTERED_NOT_VERIFIED) {
    return BookAppointmentStep.EMAIL_VERIFICATION;
  } else if (!insuranceReady && !shouldShowAnthemEAPExperience) {
    return BookAppointmentStep.INSURANCE;
  } else if (!isEAPReady && shouldShowAnthemEAPExperience) {
    return BookAppointmentStep.EAP;
  } else if (!appointment) {
    if (shouldShowAnthemEAPExperience) {
      return BookAppointmentStep.EAP_CHECKOUT;
    } else {
      return BookAppointmentStep.CHECKOUT;
    }
  } else {
    return BookAppointmentStep.SUCCESS;
  }
};

const isAppointmentInNonBookableRange = ({
  availability,
  provider,
}: {
  availability: AvailabilityEventResponse | null;
  provider: ProviderRead;
}) => {
  return (
    !!availability &&
    moment(availability.startDate).isBefore(
      moment().add(provider.soonestBookingCutoffHours, 'hours')
    )
  );
};

const isAppointmentTypeMismatch = ({
  availability,
  bookingPreference,
}: {
  availability: AvailabilityEventResponse | null;
  bookingPreference: ProviderSpecialtyBookingPreference;
}) => {
  return (
    !!availability &&
    ((availability.type === ProviderEventType.APPOINTMENT &&
      bookingPreference === ProviderSpecialtyBookingPreference.INTAKE_CALL) ||
      (availability.type === ProviderEventType.INTAKE_CALL &&
        bookingPreference === ProviderSpecialtyBookingPreference.APPOINTMENT))
  );
};

const getNextBookAppointmentStep = ({
  currentStep,
  appointment,
  insuranceReady,
  isEAPReady,
  shouldShowAnthemEAPExperience,
  availability,
  isInOutage,
}: {
  currentStep: BookAppointmentStep;
  appointment?: ConcreteProviderEventRead;
  insuranceReady: boolean;
  isEAPReady: boolean;
  shouldShowAnthemEAPExperience: boolean;
  availability: AvailabilityEventResponse | null;
  isInOutage: boolean;
}) => {
  // Move from successful current step to next step
  if (currentStep === BookAppointmentStep.ACCOUNT) {
    return BookAppointmentStep.EMAIL_VERIFICATION;
  }

  if (currentStep === BookAppointmentStep.EMAIL_VERIFICATION) {
    if (!insuranceReady && !shouldShowAnthemEAPExperience) {
      return BookAppointmentStep.INSURANCE;
    } else if (!isEAPReady && shouldShowAnthemEAPExperience) {
      return BookAppointmentStep.EAP;
    }

    if (!appointment) {
      if (shouldShowAnthemEAPExperience) {
        return BookAppointmentStep.EAP_CHECKOUT;
      } else {
        return BookAppointmentStep.CHECKOUT;
      }
    } else {
      return BookAppointmentStep.SUCCESS;
    }
  }

  if (currentStep === BookAppointmentStep.INSURANCE) {
    if (availability && availability.telehealth) {
      return BookAppointmentStep.TELEHEALTH;
    }

    if (isInOutage && availability?.type === ProviderEventType.APPOINTMENT) {
      return BookAppointmentStep.ACKNOWLEDGEMENT;
    }

    return BookAppointmentStep.CHECKOUT;
  }

  if (currentStep === BookAppointmentStep.EAP) {
    return BookAppointmentStep.EAP_CHECKOUT;
  }

  if (currentStep === BookAppointmentStep.TELEHEALTH) {
    if (isInOutage && availability?.type === ProviderEventType.APPOINTMENT) {
      return BookAppointmentStep.ACKNOWLEDGEMENT;
    } else {
      return BookAppointmentStep.CHECKOUT;
    }
  }

  if (currentStep === BookAppointmentStep.ACKNOWLEDGEMENT) {
    return BookAppointmentStep.CHECKOUT;
  }

  if (
    currentStep === BookAppointmentStep.CHECKOUT ||
    currentStep === BookAppointmentStep.EAP_CHECKOUT
  ) {
    return BookAppointmentStep.SUCCESS;
  }
  return currentStep;
};

/**
 * Returns the previous book appointment step if it exists, or null if should route to last viewed screen
 */
const getPreviousBookAppointmentStep = ({
  currentStep,
  availability,
  isInOutage,
}: {
  currentStep: BookAppointmentStep;
  availability: AvailabilityEventResponse | null;
  isInOutage: boolean;
}) => {
  const isTelehealth = availability && availability.telehealth;
  if (currentStep === BookAppointmentStep.CHECKOUT) {
    if (isInOutage) {
      return BookAppointmentStep.ACKNOWLEDGEMENT;
    } else if (isTelehealth) {
      return BookAppointmentStep.TELEHEALTH;
    } else {
      return BookAppointmentStep.INSURANCE;
    }
  }

  if (currentStep === BookAppointmentStep.ACKNOWLEDGEMENT) {
    if (isTelehealth) {
      return BookAppointmentStep.TELEHEALTH;
    } else {
      return BookAppointmentStep.INSURANCE;
    }
  }

  if (currentStep === BookAppointmentStep.TELEHEALTH) {
    return BookAppointmentStep.INSURANCE;
  }

  if (currentStep === BookAppointmentStep.EAP_CHECKOUT) {
    return BookAppointmentStep.EAP;
  }

  return null;
};

const userHasInsuranceInfo = ({
  user,
  claimReadiness,
  provider,
  patientInsuranceStatus,
}: {
  user: UserRead;
  claimReadiness: UserClaimReadinessResponse | undefined;
  provider: ProviderRead;
  patientInsuranceStatus: PatientInsuranceOrEAPStatus | undefined;
}) => {
  const requiredInsuranceFields = [
    user.activeUserInsurance?.frontEndCarrierId,
    user.activeUserInsurance?.memberId,
    user.firstName,
    user.lastName,
    user.dob,
    user.lastSearchedState,
  ];

  const isMissingRequiredInsuranceFields = requiredInsuranceFields.some(
    (field) => field === undefined || field === null || field === ''
  );

  const isClaimReadinessValid =
    claimReadiness?.ok === true && claimReadiness.userId === user.id;

  const insuranceReady =
    !isMissingRequiredInsuranceFields &&
    isClaimReadinessValid &&
    patientInsuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK &&
    !isOutOfState(
      getProviderActiveAndLiveStates(provider),
      user.lastSearchedState,
      user.activeUserInsurance
    );

  return insuranceReady;
};

function getAvailabilitySelectionReason(availabilitySelectionReasonData: {
  availability?: AvailabilityEventResponse;
  bookingPreference: ProviderSpecialtyBookingPreference;
  isInsuranceOONWithOfficeAddress: boolean;
  doesInsuranceRequireTelehealth: boolean;
  requestedStartDate?: Date;
}): AvailabilitySelectionReason {
  const {
    availability,
    bookingPreference,
    isInsuranceOONWithOfficeAddress,
    doesInsuranceRequireTelehealth,
    requestedStartDate,
  } = availabilitySelectionReasonData;

  if (!requestedStartDate) {
    return AvailabilitySelectionReason.NO_TIMESLOT_SELECTED;
  }

  if (!availability) {
    return AvailabilitySelectionReason.ORIGINAL_TIMESLOT_NOT_AVAILABLE;
  }

  if (
    availability.type === ProviderEventType.APPOINTMENT &&
    bookingPreference === ProviderSpecialtyBookingPreference.INTAKE_CALL
  ) {
    return AvailabilitySelectionReason.PROVIDER_REQUIRES_FIRST_VISIT_INTAKE_CALL;
  }

  if (
    availability.type === ProviderEventType.INTAKE_CALL &&
    bookingPreference === ProviderSpecialtyBookingPreference.APPOINTMENT
  ) {
    return AvailabilitySelectionReason.PROVIDER_REQUIRES_FIRST_VISIT_FULL_APPOINTMENT;
  }

  if (isInsuranceOONWithOfficeAddress) {
    return AvailabilitySelectionReason.OFFICE_LOCATION_OUT_OF_NETWORK;
  }

  if (doesInsuranceRequireTelehealth) {
    return AvailabilitySelectionReason.INSURANCE_ONLY_COVERS_TELEHEALTH;
  }

  return AvailabilitySelectionReason.ORIGINAL_TIMESLOT_NOT_AVAILABLE;
}

function getRequestedStartDateFromQuery(
  query: ParsedUrlQuery
): moment.Moment | null {
  const rawStartDate = query.startDate;

  if (!rawStartDate) {
    return null;
  }

  if (typeof rawStartDate !== 'string') {
    logWarning('Start date is invalid type, expected string', {
      extra: { rawStartDate, type: typeof rawStartDate },
    });
    return null;
  }

  const startDate = moment(
    // Datetime strings can have `+` signs in them to denote timezone
    // which get converted to spaces when URL decoded from the URL
    rawStartDate.replace(' ', '+'),
    moment.ISO_8601,
    true // enable strict parsing
  );

  if (!startDate.isValid()) {
    logWarning('Invalid start date', { extra: { startDate, rawStartDate } });
    return null;
  }

  return startDate;
}

function getRequestedLocationFromQuery(
  query: ParsedUrlQuery
):
  | UserSessionLocationPreference.IN_PERSON
  | UserSessionLocationPreference.VIRTUAL
  | null {
  const { locationPreference } = query;

  if (!locationPreference) {
    return null;
  }

  if (
    !isValidEnumValue(UserSessionLocationPreference, locationPreference) ||
    (locationPreference !== UserSessionLocationPreference.IN_PERSON &&
      locationPreference !== UserSessionLocationPreference.VIRTUAL)
  ) {
    logWarning('Invalid location preference', {
      extra: { locationPreference },
    });
    return null;
  }

  return locationPreference;
}

function getRequestedAppointmentTypeFromQuery(
  query: ParsedUrlQuery
): ProviderEventType.APPOINTMENT | ProviderEventType.INTAKE_CALL | null {
  const { type } = query;
  if (!type) {
    return null;
  }

  if (
    !isValidEnumValue(ProviderEventType, type) ||
    (type !== ProviderEventType.APPOINTMENT &&
      type !== ProviderEventType.INTAKE_CALL)
  ) {
    logWarning('Invalid provider event type', { extra: { type } });
    return null;
  }

  return type;
}

function getRequestedAddressIdFromQuery(query: ParsedUrlQuery): number | null {
  const { providerAddressId } = query;

  if (!providerAddressId) {
    return null;
  }

  if (typeof providerAddressId !== 'string') {
    logWarning('Invalid provider address ID, expected string', {
      extra: { providerAddressId, type: typeof providerAddressId },
    });
    return null;
  }

  const parsedProviderAddressId = Number(providerAddressId);

  if (
    !Number.isInteger(parsedProviderAddressId) ||
    !Number.isFinite(parsedProviderAddressId) ||
    isNaN(parsedProviderAddressId) ||
    parsedProviderAddressId < 0
  ) {
    logWarning('Invalid provider address ID', {
      extra: { providerAddressId, parsedProviderAddressId },
    });
    return null;
  }

  return parsedProviderAddressId;
}

const getPriceEstimateSavingsCardContent = ({
  priceEstimate,
  pricingCardStatus,
  provider,
}: {
  priceEstimate: UserPriceEstimateResponse;
  pricingCardStatus: PricingCardStatus;
  provider: ProviderRead;
}): PriceEstimateSavingsCardProps | undefined => {
  if (
    !PRICING_CARD_STATUSES_WITH_SAVINGS_CARD_CONTENT.includes(pricingCardStatus)
  ) {
    return undefined;
  }
  const description = determinePriceEstimateSavingsCardDescription({
    priceEstimate,
    pricingCardStatus,
    provider,
  });
  const priceBreakdowns = determinePriceEstimateSavingsCardPriceBreakdowns({
    priceEstimate,
    pricingCardStatus,
  });

  return {
    priceEstimateDescription: description,
    priceBreakdowns: priceBreakdowns,
  };
};

const determinePriceEstimateSavingsAmount = (
  priceEstimate: UserPriceEstimateResponse
): number => {
  if (
    priceEstimate.firstSessionMaxServiceCost === undefined ||
    priceEstimate.otherSessionsMaxServiceCost === undefined ||
    priceEstimate.firstSessionMinPrice === undefined ||
    priceEstimate.otherSessionsMinPrice === undefined
  ) {
    return 0;
  }
  const firstSessionSavings =
    priceEstimate.firstSessionMaxServiceCost -
    priceEstimate.firstSessionMinPrice;
  const otherSessionsSavings =
    priceEstimate.otherSessionsMaxServiceCost -
    priceEstimate.otherSessionsMinPrice;

  return max([firstSessionSavings, otherSessionsSavings]) ?? 0;
};

const determinePriceEstimateSavingsCardDescription = ({
  priceEstimate,
  pricingCardStatus,
  provider,
}: {
  priceEstimate: UserPriceEstimateResponse;
  pricingCardStatus: PricingCardStatus;
  provider: ProviderRead;
}): string => {
  if (
    !PRICING_CARD_STATUSES_WITH_SAVINGS_CARD_CONTENT.includes(pricingCardStatus)
  ) {
    return '';
  }

  const savingsAmount = determinePriceEstimateSavingsAmount(priceEstimate);
  const formattedSavings = savingsAmount.toLocaleString('en-us', {
    style: 'currency',
    currency: 'usd',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  switch (pricingCardStatus) {
    case PricingCardStatus.COPAY_DUE:
    case PricingCardStatus.ZERO_DOLLAR_PRICE:
      return `You’re saving up to ${formattedSavings} per session on your out-of-pocket costs with Headway.`;

    case PricingCardStatus.DEDUCTIBLE_REMAINING:
    case PricingCardStatus.DEDUCTIBLE_REMAINING_RANGE:
      return `After you meet your deductible, you’ll save up to ${formattedSavings} per session on your out-of-pocket costs by using your insurance with Headway!`;

    case PricingCardStatus.DEDUCTIBLE_REMAINING_PRESCRIBER:
      return `We won't know your price until your session is complete because ${provider.displayFirstName} prescribes medication.`;

    default:
      return `You’ll save up to ${formattedSavings} for most sessions on your out-of-pocket costs with Headway.`;
  }
};

const determinePriceEstimateSavingsCardPriceBreakdowns = ({
  priceEstimate,
  pricingCardStatus,
}: {
  priceEstimate: UserPriceEstimateResponse;
  pricingCardStatus: PricingCardStatus;
}): PriceBreakdownProps[] => {
  if (
    !PRICING_CARD_STATUSES_WITH_SAVINGS_CARD_CONTENT.includes(pricingCardStatus)
  ) {
    return [];
  }

  if (
    !priceEstimate.firstSessionMinPrice ||
    !priceEstimate.otherSessionsMinPrice ||
    !priceEstimate.firstSessionMaxPrice ||
    !priceEstimate.otherSessionsMaxPrice
  ) {
    return [];
  }

  const formattedFirstSessionMinPrice =
    priceEstimate.firstSessionMinPrice.toLocaleString('en-us', {
      style: 'currency',
      currency: 'usd',
      maximumFractionDigits: 0,
    });
  const formattedFirstSessionMaxPrice =
    priceEstimate.firstSessionMaxPrice.toLocaleString('en-us', {
      style: 'currency',
      currency: 'usd',
      maximumFractionDigits: 0,
    });
  const firstSessionHasRange =
    priceEstimate.firstSessionMinPrice !== priceEstimate.firstSessionMaxPrice;
  const formattedOtherSessionsMinPrice =
    priceEstimate.otherSessionsMinPrice.toLocaleString('en-us', {
      style: 'currency',
      currency: 'usd',
      maximumFractionDigits: 0,
    });
  const formattedOtherSessionsMaxPrice =
    priceEstimate.otherSessionsMaxPrice.toLocaleString('en-us', {
      style: 'currency',
      currency: 'usd',
      maximumFractionDigits: 0,
    });
  const otherSessionsHasRange =
    priceEstimate.otherSessionsMinPrice !== priceEstimate.otherSessionsMaxPrice;

  switch (pricingCardStatus) {
    case PricingCardStatus.COPAY_DUE:
    case PricingCardStatus.ZERO_DOLLAR_PRICE:
      return [];

    case PricingCardStatus.DEDUCTIBLE_REMAINING:
    case PricingCardStatus.DEDUCTIBLE_REMAINING_RANGE:
    case PricingCardStatus.DEDUCTIBLE_REMAINING_PRESCRIBER:
      return [
        {
          title: firstSessionHasRange
            ? `${formattedFirstSessionMinPrice}-${formattedFirstSessionMaxPrice}`
            : formattedFirstSessionMinPrice,
          subtitle: 'for your first session',
        },
        {
          title: otherSessionsHasRange
            ? `${formattedOtherSessionsMinPrice}-${formattedOtherSessionsMaxPrice}`
            : formattedOtherSessionsMinPrice,
          subtitle: 'after you meet your deductible',
        },
      ];

    default:
      return [];
  }
};

// This DD tracking is for the new checkout flow only
const trackDatadogRumAction = ({
  step,
  action,
  metadata,
  isNewFlow,
}: {
  step: BookAppointmentStep;
  action: string;
  metadata: object;
  isNewFlow: boolean;
}) => {
  if (!isNewFlow) {
    return;
  }

  datadogRum.addAction(datadogActionName({ step, action }), {
    ...metadata,
    step: step,
  });
};

const datadogActionName = ({
  step,
  action,
}: {
  step: BookAppointmentStep;
  action: string;
}) => {
  return `${DATADOG_RUM_PREFIX} : ${step} : ${action}`;
};

export {
  isAppointmentInNonBookableRange,
  isAppointmentTypeMismatch,
  getNextBookAppointmentStep,
  getPreviousBookAppointmentStep,
  userHasInsuranceInfo,
  getInitialBookAppointmentStep,
  getAvailabilitySelectionReason,
  getRequestedStartDateFromQuery,
  getRequestedLocationFromQuery,
  getRequestedAppointmentTypeFromQuery,
  getRequestedAddressIdFromQuery,
  isTerminalStep,
  getPriceEstimateSavingsCardContent,
  trackDatadogRumAction,
  datadogActionName,
};
