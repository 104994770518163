import keyBy from 'lodash/keyBy';
import moment from 'moment';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { MessageType } from '@headway/api/models/MessageType';
import { PatientConcernSource } from '@headway/api/models/PatientConcernSource';
import { ProviderEventCreate } from '@headway/api/models/ProviderEventCreate';
import { ProviderPatientNested } from '@headway/api/models/ProviderPatientNested';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderSpecialtyBookingPreference } from '@headway/api/models/ProviderSpecialtyBookingPreference';
import { SpecialtyRead } from '@headway/api/models/SpecialtyRead';
import { UserPriceEstimateResponse } from '@headway/api/models/UserPriceEstimateResponse';
import { UserRead } from '@headway/api/models/UserRead';
import { AuthApi } from '@headway/api/resources/AuthApi';
import { FrontEndCarrierApi } from '@headway/api/resources/FrontEndCarrierApi';
import { MessageApi } from '@headway/api/resources/MessageApi';
import { PatientConcernApi } from '@headway/api/resources/PatientConcernApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { UserEmployeeAssistanceProgramApi } from '@headway/api/resources/UserEmployeeAssistanceProgramApi';
import { useProviderPatientInsuranceStatusQuery } from '@headway/shared/hooks/useProviderPatientInsuranceStatusQuery';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@headway/shared/react-query';
import { getPriceString } from '@headway/shared/utils/bookingUtils';
import {
  getVerificationStatus,
  UserVerificationStatus,
} from '@headway/shared/utils/userVerificationStatus';
import { useMarkets } from '@headway/ui/providers/MarketProvider';

import { usePatientAssessments } from '../../hooks/usePatientAssessments';
import {
  getCarriersAndProviderPriceCacheKey,
  PROVIDER_HIGHLIGHTS_CACHE_KEY,
  VERIFY_CLAIM_READINESS_QUERY_KEY,
} from '../../utils/cacheKeys';
import { getPricingCardStatusFromPricingDetails } from '../Providers/PricingCard/utils';
import { PriceEstimateSavingsCardProps } from './components/PriceEstimateSavingsCard';
import { ACCOUNT_STEP_HIGHLIGHTS, BookAppointmentStep } from './constants';
import {
  getPriceEstimateSavingsCardContent,
  isAppointmentInNonBookableRange,
  isAppointmentTypeMismatch,
  userHasInsuranceInfo,
} from './utils';

type CarriersAndProviderPriceQueryResult = [
  FrontEndCarrierRead[],
  UserPriceEstimateResponse,
];

const getInitialBookAppointmentStep = ({
  user,
  provider,
  appointment,
  availability,
  insuranceReady,
  bookingPreference,
  shouldShowAnthemEAPExperience,
  isEAPReady,
}: {
  user: UserRead;
  provider: ProviderRead;
  appointment?: ConcreteProviderEventRead;
  availability: AvailabilityEventResponse | null;
  insuranceReady: boolean;
  bookingPreference: ProviderSpecialtyBookingPreference;
  shouldShowAnthemEAPExperience: boolean;
  isEAPReady: boolean;
}) => {
  const accountStatus = getVerificationStatus(user);

  const appointmentInNonBookableRange = isAppointmentInNonBookableRange({
    availability: availability,
    provider: provider,
  });

  const appointmentTypeMismatch = isAppointmentTypeMismatch({
    availability: availability,
    bookingPreference: bookingPreference,
  });

  // Function to get the starting point for a user when the page loads
  if (
    !availability ||
    appointmentInNonBookableRange ||
    appointmentTypeMismatch
  ) {
    return BookAppointmentStep.CONFLICT;
  } else if (accountStatus === UserVerificationStatus.UNREGISTERED) {
    return BookAppointmentStep.ACCOUNT;
  } else if (accountStatus === UserVerificationStatus.REGISTERED_NOT_VERIFIED) {
    return BookAppointmentStep.EMAIL_VERIFICATION;
  } else if (!insuranceReady && !shouldShowAnthemEAPExperience) {
    return BookAppointmentStep.INSURANCE;
  } else if (!isEAPReady && shouldShowAnthemEAPExperience) {
    return BookAppointmentStep.EAP;
  } else if (!appointment) {
    return BookAppointmentStep.CHECKOUT;
  } else {
    return BookAppointmentStep.SUCCESS;
  }
};

const useGetInitialInsuranceReadyStatus = ({
  user,
  provider,
}: {
  user: UserRead;
  provider: ProviderRead;
}) => {
  const patientInsuranceStatusQuery = useProviderPatientInsuranceStatusQuery(
    {
      patientUserId: user.id,
      providerId: provider.id,
    },
    // Stale time is infinity so that query does not refetch, since this hook represents the initial insurance status
    {
      staleTime: Infinity,
    }
  );

  const claimReadinessQuery = useQuery(
    [VERIFY_CLAIM_READINESS_QUERY_KEY, user.activeUserInsuranceId],
    () => {
      if (!user.activeUserInsuranceId) {
        return null;
      }
      return UserApi.getClaimReadiness(user.id);
    },
    // Stale time is infinity so that the query does not refetch, since this hook only exposes the initial insurance status
    {
      staleTime: Infinity,
    }
  );

  const isLoading =
    patientInsuranceStatusQuery.isLoading || claimReadinessQuery.isLoading;

  const insuranceReady = userHasInsuranceInfo({
    user: user,
    claimReadiness: claimReadinessQuery.data ?? undefined,
    provider: provider,
    patientInsuranceStatus:
      patientInsuranceStatusQuery?.data?.patientInsuranceStatus,
  });

  return { data: insuranceReady, isLoading };
};

export const USER_ME_QUERY_KEY = 'user-me';

const useBookAppointmentGetUserMeQuery = ({
  onSuccess,
}: {
  onSuccess: (user: UserRead) => void;
}) => {
  return useQuery(
    [USER_ME_QUERY_KEY],
    async () => {
      return UserApi.getUserMe();
    },
    {
      staleTime: Infinity,
      // If we've successfully sent a verification email, poll the user-me endpoint every 3seconds until
      // we get back a verified user
      refetchInterval: (user) => {
        const accountStatus = getVerificationStatus(user!);
        return accountStatus ===
          UserVerificationStatus.REGISTERED_NOT_VERIFIED && !user?.isVerified
          ? 3000
          : false;
      },
      onSuccess: onSuccess,
    }
  );
};

const selectCarriersByIdAndPriceEstimateFromQuery = (
  data: [FrontEndCarrierRead[], UserPriceEstimateResponse]
): {
  carriersById: Record<number, FrontEndCarrierRead>;
  priceEstimate: UserPriceEstimateResponse;
} => {
  return {
    carriersById: keyBy(data[0], 'id'),
    priceEstimate: data[1],
  };
};

function useCarriersAndProviderPriceQuery<
  TSelectData = CarriersAndProviderPriceQueryResult,
>({
  providerId,
  patientUserId,
  activeUserInsuranceId,
  options,
}: {
  providerId: number;
  patientUserId: number;
  activeUserInsuranceId?: number;
  options?: UseQueryOptions<
    CarriersAndProviderPriceQueryResult,
    unknown,
    TSelectData,
    ReturnType<typeof getCarriersAndProviderPriceCacheKey>
  >;
}) {
  return useQuery(
    getCarriersAndProviderPriceCacheKey({
      providerId: providerId,
      patientUserId: patientUserId,
      activeUserInsuranceId: activeUserInsuranceId,
    }),
    () =>
      Promise.all([
        FrontEndCarrierApi.getFrontEndCarriers(),
        ProviderApi.getProviderPrice(providerId, patientUserId),
      ]),
    { staleTime: Infinity, ...options }
  );
}

const useGetProviderHighlightsQuery = ({
  providerId,
  userId,
  select,
}: {
  providerId: number;
  userId: number;
  select?: (data: any) => any;
}) => {
  return useQuery(
    [PROVIDER_HIGHLIGHTS_CACHE_KEY, providerId, userId],
    () => {
      return ProviderApi.getProviderHighlights(providerId, {
        user_id: userId,
        user_tzinfo: moment.tz.guess(),
        requested_provider_highlights: ACCOUNT_STEP_HIGHLIGHTS,
      });
    },
    {
      staleTime: 10 * 60 * 1000,
      select: select,
    }
  );
};

const useFindUserEmployeeAssistanceProgramsWithUnusedSessions = ({
  userId,
  hasUnusedSessions,
}: {
  userId: number;
  hasUnusedSessions: boolean;
}) => {
  return useQuery(['find-user-employee-assistance-programs'], () => {
    return UserEmployeeAssistanceProgramApi.findUserEmployeeAssistancePrograms({
      user_id: userId,
      has_unused_sessions: hasUnusedSessions,
    });
  });
};

const useSendVerificationMutation = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  return useMutation(
    async (variables: { userId: number; redirectTo: string }) => {
      return await AuthApi.sendVerificationEmail(variables.userId, {
        redirectTo: variables.redirectTo,
      });
    },
    {
      onSuccess: onSuccess,
    }
  );
};

const useCreatePatientConcernsMutation = () => {
  return useMutation(
    async (variables: {
      user: UserRead;
      provider: ProviderRead;
      concerns: SpecialtyRead[];
    }) => {
      const { user, provider, concerns } = variables;

      return await Promise.all(
        concerns.map((concern) =>
          PatientConcernApi.createPatientConcern({
            providerId: provider.id,
            source: PatientConcernSource.APPOINTMENT_BOOKING,
            specialtyId: concern.id,
            userId: user.id,
          })
        )
      );
    }
  );
};

const useSendMessageToProviderMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (variables: {
      message: string;
      provider: ProviderRead;
      user: UserRead;
      appointment: ConcreteProviderEventRead;
    }) => {
      const { message, provider, user, appointment } = variables;

      const content = message?.trimEnd();

      if (content) {
        return await MessageApi.createMessage(provider.id, user.id, {
          content,
          toProvider: true,
          type: MessageType.GENERAL,
          providerEventId: appointment.id,
        });
      }
    },
    onSettled: (_data, _error, variables) => {
      const messagesCacheKey = ['messages', variables.user.id];
      queryClient.invalidateQueries({ queryKey: messagesCacheKey });
    },
  });
};

const useCreateProviderEventMutation = () => {
  return useMutation(async (variables: { input: ProviderEventCreate }) => {
    const { input } = variables;
    return await ProviderEventApi.createEvent(input);
  });
};

export const usePriceEstimates = ({
  provider,
  user,
}: {
  provider: ProviderRead;
  user: UserRead;
}) => {
  const { liveMarkets } = useMarkets();
  const carriersAndProviderPriceQuery = useCarriersAndProviderPriceQuery({
    providerId: provider.id,
    patientUserId: user.id,
    activeUserInsuranceId: user.activeUserInsuranceId,
    options: { select: selectCarriersByIdAndPriceEstimateFromQuery },
  });

  const priceEstimate = carriersAndProviderPriceQuery.data?.priceEstimate;
  const carriersById = carriersAndProviderPriceQuery.data?.carriersById;

  const userCarrier = user.activeUserInsurance
    ? carriersById?.[user.activeUserInsurance.frontEndCarrierId]
    : undefined;

  const pricingCardStatus = priceEstimate
    ? getPricingCardStatusFromPricingDetails(
        priceEstimate,
        provider.isPrescriber,
        liveMarkets,
        user.lastSearchedState,
        userCarrier,
        user.activeUserInsurance
      )
    : undefined;

  const priceEstimateSavingsCardProps:
    | PriceEstimateSavingsCardProps
    | undefined =
    priceEstimate && pricingCardStatus
      ? getPriceEstimateSavingsCardContent({
          priceEstimate,
          pricingCardStatus,
          provider,
        })
      : undefined;

  const priceEstimateString = getPriceString(
    priceEstimate?.firstSessionMinPrice,
    priceEstimate?.firstSessionMaxPrice
  );

  return {
    priceEstimate,
    priceEstimateSavingsCardProps,
    priceEstimateString,
  };
};

/**
 * This hook will return a boolean in the data field representing whether an assessment for the given ProviderPatient has been created within the last 1 minute.
 * This is used to show the patient a CTA to complete their assessment as soon as they finish creating their appointment.
 * @param providerPatient Represents the ProviderPatient object that we want to find an assesssment for. If undefined, the query will not be enabled
 * @returns
 */
const useHasAssessmentToComplete = (
  providerPatient?: ProviderPatientNested
) => {
  return usePatientAssessments(
    {
      provider_patient_id: providerPatient?.id,
      limit: 1,
      order_by: 'created_on',
      order: 'desc',
    },
    {
      select: (data) => {
        const latestAssessment = data.data[0];

        if (!latestAssessment) {
          return false;
        }
        const oneMinuteAgo = moment().subtract(1, 'minutes');
        const wasCreatedWithinLastMinute = moment(
          latestAssessment.createdOn
        ).isAfter(oneMinuteAgo);

        if (wasCreatedWithinLastMinute && !latestAssessment.completedOn) {
          return true;
        }

        return false;
      },
      enabled: !!providerPatient,
    }
  );
};

export {
  useBookAppointmentGetUserMeQuery,
  useGetInitialInsuranceReadyStatus,
  getInitialBookAppointmentStep,
  useCarriersAndProviderPriceQuery,
  useGetProviderHighlightsQuery,
  useFindUserEmployeeAssistanceProgramsWithUnusedSessions,
  useSendVerificationMutation,
  useCreatePatientConcernsMutation,
  useSendMessageToProviderMutation,
  useCreateProviderEventMutation,
  useHasAssessmentToComplete,
  selectCarriersByIdAndPriceEstimateFromQuery,
};
