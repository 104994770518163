/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { UserEmployeeAssistanceProgramCreate } from '../models/UserEmployeeAssistanceProgramCreate';
import { UserEmployeeAssistanceProgramRead } from '../models/UserEmployeeAssistanceProgramRead';
import { UserEmployeeAssistanceProgramUpdate } from '../models/UserEmployeeAssistanceProgramUpdate';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class UserEmployeeAssistanceProgramApiResource {

    /**
     * Create Employee Assistance Program
     * 
     * @param userEmployeeAssistanceProgramCreate 
     
     */
    public createEmployeeAssistanceProgram(body: UserEmployeeAssistanceProgramCreate, axiosConfig?: AxiosRequestConfig): Promise<UserEmployeeAssistanceProgramRead> {
        const apiRoute = '/user-employee-assistance-program';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find User Employee Assistance Programs
     * 
     * @param userId 
     * @param dateOfService 
     * @param hasUnusedSessions 
     * @param includeNeedsConfirmation 
     
     */
    public findUserEmployeeAssistancePrograms(query?: { user_id: number, date_of_service?: string, has_unused_sessions?: boolean, include_needs_confirmation?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<Array<UserEmployeeAssistanceProgramRead>> {
        const apiRoute = '/user-employee-assistance-program';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Employee Assistance Program
     * 
     * @param userEmployeeAssistanceProgramId 
     * @param userEmployeeAssistanceProgramUpdate 
     
     */
    public updateEmployeeAssistanceProgram(userEmployeeAssistanceProgramId: number, body: UserEmployeeAssistanceProgramUpdate, axiosConfig?: AxiosRequestConfig): Promise<UserEmployeeAssistanceProgramRead> {
        const apiRoute = '/user-employee-assistance-program/{user_employee_assistance_program_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_employee_assistance_program_id}', String(userEmployeeAssistanceProgramId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const UserEmployeeAssistanceProgramApi = new UserEmployeeAssistanceProgramApiResource();
