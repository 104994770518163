import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderAddressApi } from '@headway/api/resources/ProviderAddressApi';
import { useQuery } from '@headway/shared/react-query';
import { trackPageView } from '@headway/shared/utils/analytics';
import { patientCanOnlySeeVirtualAppts } from '@headway/shared/utils/insuranceUtils';
import { checkExhaustive } from '@headway/shared/utils/types';
import { theme } from '@headway/ui/theme';

import { useRouter } from '../../hooks/useRouter';
import { AnalyticsPatientFlow } from '../../utils/analytics';
import { BookingAvailability } from '../Providers/BookingModal/BookingAvailability';
import {
  trackAvailabilitySlotSelected,
  trackSelectAvailabilityCompleted,
  trackSelectAvailabilityStarted,
} from '../Providers/ProfilePage/analyticsEvents';
import { getAvailabilityBookingUrl } from '../Providers/ProfilePage/ProviderPriceAndAvailability';
import BookAppointmentHelixVariantHeadline from './components/BookAppointmentHelixVariantHeadline';
import { BookAppointmentStep } from './constants';
import { trackDatadogRumAction } from './utils';

type OriginalTimeslotNotAvailableProps = {
  availabilitySelectionReason: AvailabilitySelectionReason.ORIGINAL_TIMESLOT_NOT_AVAILABLE;
};

type NoTimeslotSelectedProps = {
  availabilitySelectionReason: AvailabilitySelectionReason.NO_TIMESLOT_SELECTED;
};

type BookAppointmentAvailabilityOtherProps = {
  availability?: AvailabilityEventResponse;
  availabilitySelectionReason: Exclude<
    AvailabilitySelectionReason,
    | AvailabilitySelectionReason.ORIGINAL_TIMESLOT_NOT_AVAILABLE
    | AvailabilitySelectionReason.NO_TIMESLOT_SELECTED
  >;
};

export enum AvailabilitySelectionReason {
  NO_TIMESLOT_SELECTED = 'NO_TIMESLOT_SELECTED',
  ORIGINAL_TIMESLOT_NOT_AVAILABLE = 'ORIGINAL_TIMESLOT_NOT_AVAILABLE',
  PROVIDER_REQUIRES_FIRST_VISIT_INTAKE_CALL = 'PROVIDER_REQUIRES_FIRST_VISIT_INTAKE_CALL',
  PROVIDER_REQUIRES_FIRST_VISIT_FULL_APPOINTMENT = 'PROVIDER_REQUIRES_FIRST_VISIT_FULL_APPOINTMENT',
  OFFICE_LOCATION_OUT_OF_NETWORK = 'OFFICE_LOCATION_OUT_OF_NETWORK',
  INSURANCE_ONLY_COVERS_TELEHEALTH = 'INSURANCE_ONLY_COVERS_TELEHEALTH',
}

type BaseBookAppointmentAvailabilityProps = {
  provider: ProviderRead;
  user: UserRead;
};

type BookAppointmentAvailabilityProps = BaseBookAppointmentAvailabilityProps &
  (
    | OriginalTimeslotNotAvailableProps
    | NoTimeslotSelectedProps
    | BookAppointmentAvailabilityOtherProps
  );

const isOriginalTimeslotNotAvailable = (
  props: BookAppointmentAvailabilityProps
): props is BookAppointmentAvailabilityProps &
  OriginalTimeslotNotAvailableProps => {
  return (
    props.availabilitySelectionReason ===
    AvailabilitySelectionReason.ORIGINAL_TIMESLOT_NOT_AVAILABLE
  );
};

const isNoTimeslotSelected = (
  props: BookAppointmentAvailabilityProps
): props is BookAppointmentAvailabilityProps & NoTimeslotSelectedProps => {
  return (
    props.availabilitySelectionReason ===
    AvailabilitySelectionReason.NO_TIMESLOT_SELECTED
  );
};

export const BookAppointmentAvailability = (
  props: BookAppointmentAvailabilityProps
) => {
  const { user, availabilitySelectionReason, provider } = props;

  const providerAddressQuery = useQuery(
    ['provider-address', [provider.id]],
    () => {
      return ProviderAddressApi.getAllProviderAddresses({
        provider_ids: [provider.id],
      });
    }
  );
  const isAtLeastMediumViewport = useMediaQuery(theme.media.medium);
  const isAtLeastLargeViewport = useMediaQuery(theme.media.large);

  const router = useRouter();

  useEffect(() => {
    trackSelectAvailabilityStarted({
      patientFlow: AnalyticsPatientFlow.RESCHEDULE_APPOINTMENT,
    });

    trackDatadogRumAction({
      step: BookAppointmentStep.CONFLICT,
      action: 'step viewed',
      metadata: {
        user_id: user.id,
      },
      isNewFlow: true,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isActiveUserInsuranceMedicaid = !!user.activeUserInsurance?.isMedicaid;
  const eligibilityLookupId =
    user.activeUserInsurance?.latestEligibilityLookup?.id;
  useEffect(() => {
    if (
      availabilitySelectionReason ===
        AvailabilitySelectionReason.INSURANCE_ONLY_COVERS_TELEHEALTH &&
      isActiveUserInsuranceMedicaid &&
      eligibilityLookupId
    ) {
      trackPageView({
        name: 'Telehealth Only Rebooking Page Viewed',
        properties: {
          eligibilityLookupId,
          patientUserId: user.id,
        },
      });
    }
  }, [
    availabilitySelectionReason,
    isActiveUserInsuranceMedicaid,
    eligibilityLookupId,
    user.id,
  ]);

  const title = getTitle({
    provider,
    availabilitySelectionReason,
    isMedicaid: isActiveUserInsuranceMedicaid,
  });

  /* Patient must book telehealth appointment if on specific bookingStep
      or is eligible for wrap network and don't have a blue card or provider not credentialed.
  */
  const mustBeVirtual =
    availabilitySelectionReason ===
      AvailabilitySelectionReason.INSURANCE_ONLY_COVERS_TELEHEALTH ||
    patientCanOnlySeeVirtualAppts(user, provider.providerFrontEndCarriers);

  return (
    <section>
      <div className={'pb-3'}>
        <BookAppointmentHelixVariantHeadline>
          {title}
        </BookAppointmentHelixVariantHeadline>
      </div>
      <BookingAvailability
        descriptionText={getDescription(props)}
        hideIntakeDescription={
          !!AvailabilitySelectionReason.PROVIDER_REQUIRES_FIRST_VISIT_INTAKE_CALL
        }
        provider={provider}
        daysPerPage={
          isAtLeastLargeViewport ? 5 : isAtLeastMediumViewport ? 4 : 3
        }
        user={user}
        addresses={providerAddressQuery.data ?? []}
        comingFromReferralQuestionnaire={false}
        goBackInTheReferralFlow={() => {}}
        onAvailabilitySlotClick={(autoSelected) => {
          trackAvailabilitySlotSelected({
            autoSelected,
            patientFlow: AnalyticsPatientFlow.RESCHEDULE_APPOINTMENT,
          });
        }}
        onNext={(availabilityEvent) => {
          trackSelectAvailabilityCompleted({
            patientFlow: AnalyticsPatientFlow.RESCHEDULE_APPOINTMENT,
          });
          const bookingUrl = getAvailabilityBookingUrl({
            availability: availabilityEvent,
            slug: provider.slug,
          });
          router.push(bookingUrl);
        }}
        onError={(errorMessage) => {}}
        mustBeVirtual={mustBeVirtual}
      />
    </section>
  );
};

function getTitle({
  provider,
  availabilitySelectionReason,
  isMedicaid,
}: {
  provider: ProviderRead;
  availabilitySelectionReason: AvailabilitySelectionReason;
  isMedicaid?: boolean;
}): string {
  switch (availabilitySelectionReason) {
    case AvailabilitySelectionReason.PROVIDER_REQUIRES_FIRST_VISIT_INTAKE_CALL:
      return `${provider.displayFirstName} requested a 15-minute consultation call`;
    case AvailabilitySelectionReason.PROVIDER_REQUIRES_FIRST_VISIT_FULL_APPOINTMENT:
      return `${provider.displayFirstName} wants you to schedule a full session`;
    case AvailabilitySelectionReason.OFFICE_LOCATION_OUT_OF_NETWORK:
      return `Your insurance is out of network for this session's office location`;
    case AvailabilitySelectionReason.INSURANCE_ONLY_COVERS_TELEHEALTH:
      return isMedicaid
        ? 'You’re fully covered for video sessions, but we don’t offer in-person sessions for your plan'
        : `Your insurance only covers video sessions with this provider`;
    case AvailabilitySelectionReason.ORIGINAL_TIMESLOT_NOT_AVAILABLE:
      return 'Oh no, it looks like your original appointment slot is no longer available';
    case AvailabilitySelectionReason.NO_TIMESLOT_SELECTED:
      return `Select a time to meet with ${provider.displayFirstName}`;
    default:
      checkExhaustive(availabilitySelectionReason);
  }
}

function getDescription(props: BookAppointmentAvailabilityProps) {
  if (isOriginalTimeslotNotAvailable(props)) {
    return 'Please select a new slot to continue booking with this provider.';
  }

  if (isNoTimeslotSelected(props)) {
    return 'Select a time to meet.';
  }

  const { availability, availabilitySelectionReason } = props;

  switch (availabilitySelectionReason) {
    case AvailabilitySelectionReason.PROVIDER_REQUIRES_FIRST_VISIT_INTAKE_CALL:
      return `This will replace your existing slot ${
        availability
          ? `on ${moment(availability.startDate).format('MMMM D')}`
          : ''
      }. It's free of charge and helps your provider get to know you better before you have a full session.`;
    case AvailabilitySelectionReason.PROVIDER_REQUIRES_FIRST_VISIT_FULL_APPOINTMENT:
      return `This will replace your existing slot ${
        availability
          ? `on ${moment(availability.startDate).format('MMMM D')}`
          : ''
      }. Please note, a full session could result in fees — we'll show your specific rates on the right.`;
    case AvailabilitySelectionReason.INSURANCE_ONLY_COVERS_TELEHEALTH:
      return `Please select a video session to continue booking with ${props.provider.displayFirstName}.`;
    case AvailabilitySelectionReason.OFFICE_LOCATION_OUT_OF_NETWORK:
      return `Your insurance is out of network for this session's office location. Please update your insurance or attend a telehealth session instead.`;
    default:
      checkExhaustive(availabilitySelectionReason);
  }
}
