import clsx from 'clsx';
import React, { ReactNode } from 'react';

// @ts-ignore
import styles from './HeadwayLetter.module.css';

export interface HeadlineTextProps {
  children: ReactNode;
}

export const LetterText = ({ children }: HeadlineTextProps) => {
  return (
    <p>
      <span className={clsx('text-[#5F1800]', styles.letterBodyFont)}>
        {children}
      </span>
    </p>
  );
};

export const SignatureText = ({ children }: HeadlineTextProps) => {
  return (
    <p>
      <span className={styles.signature}>{children}</span>
    </p>
  );
};

type HeadwayLetterProps = {
  providerName: string;
  patientFirstName?: string;
};

export const HeadwayLetter = ({
  providerName,
  patientFirstName,
}: HeadwayLetterProps) => {
  return (
    <div className={clsx('desktop:space-y-6 space-y-3')}>
      <LetterText>
        {patientFirstName
          ? `Warmest welcome to Headway ${patientFirstName},`
          : `Warmest welcome to Headway,`}
      </LetterText>
      <LetterText>
        This first step takes courage, and booking a session is a big moment.
        Whether you’re new to therapy or continuing your journey, we’re here for
        you — every step of the way.
      </LetterText>
      <LetterText>
        Now, we’re honored for you to meet {providerName}, who’s ready to
        understand your story and support you to reveal your best self.
      </LetterText>
      <LetterText>
        Looking forward to walking along your path to better mental health,
        together.
      </LetterText>

      <div className="desktop:pt-7 desktop:pb-8 pt-1">
        <SignatureText>Team Headway</SignatureText>
      </div>
    </div>
  );
};
